import React, {useEffect} from 'react';
import styles from './Splash.module.scss';
import { useNavigate } from 'react-router-dom';

import {
    selectCurrentLanguage,
    selectLocaleStrings,
    setCurrentLanguage,
    selectHasPhoneLayout,
  } from '../features/survey/surveySlice';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useCookies } from 'react-cookie';

import { Layout } from '../layouts/Layout';
import { HomeContent } from './Home';
import splashImage from '../images/AFEBlogo.png';
import { ACCESS_COOKIE_VALUE, REQUIRE_ACCESS_PASSWORD } from '../constants';

export function Splash() {
    const navigate = useNavigate();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const currentLanguage = useAppSelector(selectCurrentLanguage);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const dispatch = useAppDispatch();
    const [cookies] = useCookies(['onop_access']);

    useEffect(() => {
        // try to set language based on browser settings
        if (navigator.languages) {
            for (const language of navigator.languages) {
                const tokens = language.split('-');
                const lcode = tokens.length > 1 ? tokens[0] : language.toLowerCase();

                if (lcode === 'en') {
                    break;
                }
                else if (lcode === 'es') {
                    dispatch(setCurrentLanguage(lcode));
                    break;
                }
            }
        }
    }, [])

    useEffect(() => {
        document.title = `${localeStrings['project']} ${localeStrings['tocSurveyName']}`;
    }, [localeStrings]);

    const handleNext = () => {
        const isDevEnvironment = process.env.NODE_ENV === 'development';
        const hasAccess = !REQUIRE_ACCESS_PASSWORD || isDevEnvironment || (cookies.onop_access === ACCESS_COOKIE_VALUE);
        if (hasAccess) {
            navigate('/home');
        }
        else {
            // until we launch, require user to have entered password and set up cookie
            window.location.assign(`/auth/access.php`);
        }
    }

    return (
        <Layout layoutClass="layout-intro layout-splash">
            <div className={`section-intro centered framed-container ${styles.container}`}>
                <div className={styles.imageContainer}><img src={splashImage} alt="Arts for Everybody" /></div>
                { hasPhoneLayout ? <div className={styles.surveyTitle}>{ localeStrings['tocSurveyName']}</div>
                    : <div className={styles.surveyTitle}>{ localeStrings['welcome']}</div> }
                { hasPhoneLayout && <div className={styles.buttonContainer}>
                    <button
                        className="onop-button onop-button-primary"
                        aria-label={ localeStrings['continue'] }
                        onClick={() => handleNext()}
                        >{ localeStrings['continue']}</button>
                </div> }
                <div>
                    <button
                        className={styles.language}
                        aria-label={ localeStrings['changeLanguage'] }
                        onClick={() => dispatch(setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en'))}
                        >{ localeStrings['changeLanguage'] }</button>
                </div>
                <div className={styles.version}>v{process.env.REACT_APP_VERSION}</div>
            </div>
            { !hasPhoneLayout && <div className='right-panel padded-panel'>
                <HomeContent />
            </div> }
        </Layout>
    );
  }