import React, { useEffect, useRef, useState } from 'react';

import { getQuestionText } from '../utils';
import { QuestionChildProps } from './types';
import { NavigationControl } from '../../../components/NavigationControl';
import { ScrollArrow } from "../../../components/ScrollArrow";

export const StaticText = (props : QuestionChildProps) => {
    const {block, question, clientData, language, layoutType, navigationProps} = props;
    const [reveal, setReveal] = useState<boolean>(false);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const secondaryTextRef = useRef<HTMLDivElement | null>(null);
    const questionText = getQuestionText(question, language);
    const secondaryText = language && (clientData?.secondaryText ? clientData.secondaryText[language] : '');

    useEffect(() => {
        if (question.clientData?.noDelay) {
          setReveal(true);
        }
      }, [question]);

    function handleAnimationEnd() {
      setIsScrolling(!!secondaryTextRef.current && (secondaryTextRef.current.scrollHeight >  secondaryTextRef.current.clientHeight));
    }

    return (
        <div className="question-container text-container fit-y">
            { clientData?.contentType === 'html' && 
                <div className={`${question.clientData?.textClass ?? 'static-text'} fit-y`} dangerouslySetInnerHTML={{__html: questionText }}></div> }
            { clientData?.contentType !== 'html' && 
                <div className={`${question.clientData?.textClass ?? 'static-text'} fit-y`}>{ questionText }</div> }
            { secondaryText && 
                <div className={`flex-column-single-space delay-reveal relative-container fit-y`}
                  onAnimationEnd={handleAnimationEnd} >
                { isScrolling && <div className="content-relative"><ScrollArrow /></div> }
                <div ref={secondaryTextRef} className={`${question.clientData?.textClass ?? 'static-text'} scroll-y ${isScrolling ? 
                'margin-right-2' : ''}`}>{secondaryText}</div>
                </div> }
            { (layoutType !== 'desktop') && <div className={`flex-no-shrink ${ reveal || !secondaryText ? '' : 'delay-reveal'}`}>
               <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
                    enabled={navigationProps?.enabled ?? true}
                    showPrevious={navigationProps?.showPrevious ?? true} 
                    showNext={navigationProps?.showNext ?? true}
                    onPrevious={navigationProps?.onPrevious}
                    onNext={navigationProps?.onNext}
                     /></div> }
        </div>);
}
