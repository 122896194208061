import {useEffect, useMemo, useState} from 'react';
import { useCookies } from 'react-cookie';
import Lottie from "lottie-react";

import { ACCESS_COOKIE_VALUE, AP_SURVEY_ID, REQUIRE_ACCESS_PASSWORD } from '../constants';

import styles from './Splash.module.scss';
import { useNavigate } from 'react-router-dom';
import {
    selectCurrentLanguage,
    selectLocaleStrings,
    setCurrentLanguage,
    selectHasPhoneLayout,
    selectAnimationStatus,
    loadAnimationAsync,
  } from '../features/survey/surveySlice';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { animationCache, getAnimationPath } from '../features/survey/utils';

import { Layout } from '../layouts/Layout';
import splashImage from '../images/AFEBlogo.png';

export function APSplash() {
    const surveyid = AP_SURVEY_ID;  
    const navigate = useNavigate();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const currentLanguage = useAppSelector(selectCurrentLanguage);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const dispatch = useAppDispatch();
    const [cookies] = useCookies(['onop_access']);
    const [, setSessionCookie] = useCookies(['sessionId']);
    const [animation, setAnimation] = useState<any|undefined>(undefined);
    const animationStatus = useAppSelector(selectAnimationStatus);
    const animationFile = useMemo(() => (hasPhoneLayout ? '' : getAnimationPath('S4-Q1.json', hasPhoneLayout)), [hasPhoneLayout]);
    
    useEffect(() => {
      // load animation
      if (animationFile) {
        const loadStatus = animationStatus[animationFile];
        if (loadStatus === 'loaded') {
          const newAnimation = animationCache.get(animationFile);
          if (animation !== newAnimation) {
            setAnimation(newAnimation);
          }
        }
        else if (!loadStatus) {
          // load the animation
          dispatch(loadAnimationAsync(animationFile));
          setAnimation(undefined);
        }
      }
      else {
        setAnimation(undefined);
      }
    }, [animation, animationFile, animationStatus, dispatch]);

    useEffect(() => {
        // try to set language based on browser settings
        if (navigator.languages) {
            for (const language of navigator.languages) {
                const tokens = language.split('-');
                const lcode = tokens.length > 1 ? tokens[0] : language.toLowerCase();

                if (lcode === 'en') {
                    break;
                }
                else if (lcode === 'es') {
                    dispatch(setCurrentLanguage(lcode));
                    break;
                }
            }
        }
    }, []);

    useEffect(() => {
        document.title = `${localeStrings['project']} ${localeStrings['apSurveyName']}`;
    }, [localeStrings]);
    
    const handleNext = () => {
        const isDevEnvironment = process.env.NODE_ENV === 'development';
        const hasAccess = !REQUIRE_ACCESS_PASSWORD || isDevEnvironment || (cookies.onop_access === ACCESS_COOKIE_VALUE);
        if (hasAccess) {
            // clear cookie when starting from here
            setSessionCookie('sessionId', '');

            navigate(`/consent/${surveyid}/`);
        }
        else {
            // until we launch, require user to have entered password and set up cookie
            window.location.assign(`/auth/access.php`);
        }
    }
  
    return (
        <Layout layoutClass="layout-intro">
            { !hasPhoneLayout && <div className='padded-panel'>
                <div className='lottie-container'>
                    { <Lottie className="lottie" animationData={animation} loop={true} />}
                    <div className='frame-overlay'></div>
                </div>
            </div> }
            <div className={`section-intro centered content-panel padded-panel ${styles.container}`}>
                <div className={styles.imageContainer}><img src={splashImage} alt="Arts for Everybody" /></div>
                <div className={styles.surveyTitle}>{ localeStrings['apSurveyName'].replace('participación en', "participación\nen")}</div>
                <div className={styles.buttonContainer}>
                    <button
                        className="onop-button onop-button-primary"
                        aria-label={ localeStrings['continue'] }
                        onClick={() => handleNext()}
                        >{ localeStrings['continue']}</button>
                    </div>
                <div>
                <button
                    className={styles.language}
                    aria-label={ localeStrings['changeLanguage'] }
                    onClick={() => dispatch(setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en'))}
                    >{ localeStrings['changeLanguage'] }</button>
                </div>
                <div className={styles.version}>v{process.env.REACT_APP_VERSION}</div>
            </div>
        </Layout>
    );
}