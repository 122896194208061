import React from 'react';
import styles from './AppHeader.module.scss';

import { useAppSelector } from '../app/hooks';
import {
  selectLocaleStrings,
  selectClientData
} from '../features/survey/surveySlice';

interface AppHeaderProps {
  surveyType?: string;
}

export function AppHeader(props: AppHeaderProps) {
    const localeStrings = useAppSelector(selectLocaleStrings);
    const surveyClientData = useAppSelector(selectClientData);
    const surveyType = props.surveyType ?? surveyClientData.surveyType;

    return (
      <header className={ styles.header }>
        <h2 className="main-title">
            { localeStrings['project'] }
        </h2>
        <div className="main-subtitle centered">{ localeStrings[`${surveyType}SurveyName`] }</div>
      </header>
    );
  }
