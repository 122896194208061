import React, { useEffect, useRef, useState } from 'react';
import styles from './Slider.module.scss';

interface SliderProps {
    minValue: number;
    maxValue: number;
    value?: number;
}

export function Slider(props: SliderProps) {
    const { minValue, maxValue, value } = props;
    const trackRef = useRef<HTMLDivElement | null>(null);
    const [x, setX] = useState<number>(0);

    useEffect(() => {
        if (value && trackRef.current) {
            const rect = trackRef.current.getBoundingClientRect();
            const pct = Math.min(1, Math.max(0, (value - minValue) / (maxValue - minValue)));
            setX(rect.width * pct);
        }
    }, [value, minValue, maxValue, trackRef]);

    return (
      <div className={styles.container}>
        <div ref={trackRef} className={styles.track}></div>
        <div className={styles.labelContainer}>
            <div className={styles.minimum}>{minValue}</div>
            <div className={styles.maximum}>{maxValue}</div>
        </div>
        { value !== undefined && 
            <div className={`slider-thumb ${styles.thumb}`}
                style={{left:x}}></div> }
      </div>
    );
  }