import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAnimation, fetchSurvey, fetchToken, startSession, submitResponses, submitResponseSet } from './surveyAPI';
import {deleteLocalResponse, getLocalResponse, getAllLocalResponses, storeResponsesLocally} from './surveyDB';
import {LayoutType, ResponsePayload, SubmitResponsePayload, SurveyBlock, SurveyClientData, SurveyOptions, SurveyQuestion, SurveyQuestionSet, SurveyResponseSet, SurveyState} from './types';
import {animationCache, createResponseSet, getBlockForQuestion, getIndexOfQuestion, getNextQuestion, getPreviousQuestion,
  getResponsesForBlock, getSurveyClientData, lastQuestionInBlock, loadBlocksFromSurvey,
  loadQuestionSetFromSurvey, setQualtricsResponse, hasUnansweredQuestions} from './utils';
import {v4 as uuidv4} from 'uuid';
import enStrings from '../../localization/en.json';
import esStrings from '../../localization/es.json';
import { SUBMIT_RESPONSES_BY_BLOCK } from '../../constants';

const initialState: SurveyState = {
  blocks: {},
  questionSets: {},
  responseSets: {},
  currentSurveyId: '',
  currentSessionId: '',
  currentBlockId: '',
  currentQuestionId: '',
  currentLanguage: "en",
  newQuestionId: '',
  status: 'idle',
  responseStatus: 'idle',
  localeStrings: {
    'en': enStrings,
    'es': esStrings,
  },
  clientData: {
    surveyType: 'toc',
    lastQuestionId: '',
    questionData: {},
    blockNames: {},
    sectionOrder: [],
  },
  animationStatus: {},
  layoutType: 'phone',
  useProxyServer: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// loads survey without starting it
export const loadSurveyAsync = createAsyncThunk<any, SurveyOptions, {state: RootState}>(
  'survey/loadSurvey',
  async (surveyOptions: SurveyOptions, thunkApi) => {
    const surveyState = thunkApi.getState().survey;
    let surveyResponse: any;
    let surveyData: any;
    
    // get OAuth token
    if (navigator.onLine) {
      await fetchToken();
    }
    
    if (!surveyState.questionSets[surveyOptions.surveyId]) {
      surveyResponse = await fetchSurvey(surveyOptions.surveyId);
      surveyData = surveyResponse?.data.result;
    }

    // The value we return becomes the `fulfilled` action payload
    return {
          options: surveyOptions,
          survey: surveyData,
        };
  }
);

export const startSurveyAsync = createAsyncThunk<any, SurveyOptions, {state: RootState}>(
  'survey/startSurvey',
  async (surveyOptions: SurveyOptions, thunkApi) => {
    const surveyState = thunkApi.getState().survey;
    let surveyResponse: any;
    let sessionResponseSet: any;
    let qualtricsSession: string|undefined;
    let errorMessage: string|undefined;
    let useProxyServer = false;

    // get OAuth token
    if (navigator.onLine) {
      await fetchToken();
    }
    
    if (!surveyState.questionSets[surveyOptions.surveyId]) {
      surveyResponse = await fetchSurvey(surveyOptions.surveyId);
    }

    let sessionId = surveyState.currentSessionId;
    if (!sessionId) {
      if (surveyOptions.sessionId) {
        // console.log(`loading session ${surveyOptions.sessionId}`);
        
        // fetch from local database
        sessionResponseSet = await getLocalResponse(surveyOptions.sessionId);
        if (sessionResponseSet && sessionResponseSet.surveyId === surveyOptions.surveyId) {
          sessionId = surveyOptions.sessionId;
          qualtricsSession = sessionResponseSet.qualtricsSession;
        }
      }
      
      if (!sessionId || (SUBMIT_RESPONSES_BY_BLOCK && !qualtricsSession)) {
          // nothing saved, start new session
          sessionId = uuidv4();

          // start Qualtrics session
          if (SUBMIT_RESPONSES_BY_BLOCK && navigator.onLine) {
            try {
              const sessionResponse = await startSession(surveyOptions.surveyId);
              qualtricsSession = sessionResponse.data.result.sessionId;

              /* old code - abort with error 
              if (!qualtricsSession) {
                const localeStrings = surveyState.localeStrings[surveyState.currentLanguage];
                errorMessage = sessionResponse.data.result.done ?? surveyState.localeStrings['sessionError'];
              }
               */

              if (!qualtricsSession) {
                // continue without session, system should submit answers at the end
                const message = 'testing failed session start'; // sessionResponse.data.result.done ?? 'couldNotCreateSession';
                console.log(`startSurveyAsync: No session returned ${message}`);
                useProxyServer = true;
                qualtricsSession = '';
              }
            } catch (err) {
              // continue without session, system should submit answers at the end
              console.log(`startSurveyAsync: error starting session ${err}`);
              useProxyServer = true;
              qualtricsSession = '';
            }

            // console.log(`started Qualtrics session ${qualtricsSession}`);
          }
          
          sessionResponseSet = undefined;
       }
    }

    // The value we return becomes the `fulfilled` action payload
    return {
          options: surveyOptions,
          survey: surveyResponse?.data.result,
          sessionResponseSet,
          sessionId,
          qualtricsSession,
          errorMessage,
          useProxyServer,
        };
  }
);

export const submitResponsesAsync = createAsyncThunk<any, SubmitResponsePayload, {state: RootState}>(
  'survey/submitResponses',
  async (payload, thunkApi) => {
    const {direction, submitBlock} = payload;
    const surveyState = thunkApi.getState().survey;
    const sessionId = surveyState.currentSessionId;
    const responseSet = surveyState.responseSets[sessionId];
    const surveyId = surveyState.currentSurveyId;
    const questionSet = surveyState.questionSets[surveyId];
    const question = questionSet.questions[surveyState.currentQuestionId];
    let status = responseSet.status;
    let responseId: string|undefined = undefined;
    let responseDone: string|undefined = undefined;
    let nextQuestionId: string|undefined = undefined;

    if (!responseSet) {
      // should not occur
      return {
        status: 'failed'
      };
    }
    // save current state to indexed database (for offline and to support refresh)
    const copy = {...responseSet, status};
    await storeResponsesLocally(copy);

    // post this response to Qualtrics
    if (navigator.onLine) {
      if (submitBlock && responseSet.qualtricsSession) {
        // if this is the last question in a block, submit all of the responses in the
        // block now
        const block = lastQuestionInBlock(surveyState, question.questionId);
        if (block && direction !== 'back') {

          // submit blocks that occur before this one, if necessary. Qualtrics won't accept
          // responses for the current block if the previous ones haven't been submitted.
          let qualtricsQuestionId = responseSet.nextQualtricsQuestionId ?? questionSet.questionOrder[0];
          let iterationBlock = qualtricsQuestionId ? getBlockForQuestion(surveyState, qualtricsQuestionId) : undefined;
          while (iterationBlock && iterationBlock.blockId !== block.blockId) {
            const responses = getResponsesForBlock(surveyState, iterationBlock);
            const iterationResponse = await submitResponses(surveyId, responseSet.qualtricsSession, responses);
            const nextQuestions = iterationResponse?.data.result.questions;
            if (nextQuestions && nextQuestions.length > 0) {
              qualtricsQuestionId = nextQuestions[0].questionId;
              iterationBlock = qualtricsQuestionId ? getBlockForQuestion(surveyState, qualtricsQuestionId) : undefined;
            }
            else {
              break;
            }
          }

          // now submit the current block and continue
          const responses = getResponsesForBlock(surveyState, block);
          const submitResponse = await submitResponses(surveyId, responseSet.qualtricsSession, responses);
          const nextQuestions = submitResponse?.data?.result?.questions;
          responseDone = submitResponse?.data?.result?.done ?? false;
          if (nextQuestions && nextQuestions.length > 0) {
            nextQuestionId = nextQuestions[0].questionId;

            // update saved response
            const copyWithNextQuestion = {
              ...responseSet, 
              nextQualtricsQuestionId: nextQuestionId,
            };
            await storeResponsesLocally(copyWithNextQuestion);
          }
        }

        if (direction === 'finish' && !!responseDone) {
          // finished
          status = 'submitted';
          
          // delete the response set after it has been submitted
          await deleteLocalResponse(sessionId);
        }
      }
      else {
        // submit all the responses at the end
        if (question.questionId === surveyState.clientData.lastQuestionId && direction === 'finish' && status !== 'submitted') {
          const submitResponse = await submitResponseSet(responseSet, surveyState.useProxyServer);
          if (submitResponse.data.result.responseId) {
            status = 'submitted';
            responseId = submitResponse.data.result.responseId;

            // delete the response set after it has been submitted
            await deleteLocalResponse(sessionId);
          }
        }

        // in this mode, the direction should be set to finish from the compensation screen,
        // and the responses have already been submitted, so just update the status.
        if (direction === 'finish') {
          status = 'submitted';
        }
      }
    }
    else if (question.questionId === surveyState.clientData.lastQuestionId) {
      status = 'completed';
    }

    return {
      status,
      direction,
      responseId,
      nextQuestionId,
    }
  }
);

export const submitOfflineResponsesAsync = createAsyncThunk<any, SurveyResponseSet[], {state: RootState}>(
  'survey/submitOfflineResponses',
  async (offlineResponses, thunkApi) => {
    for (const responseSet of offlineResponses) {
      if (responseSet.status === 'completed') {
        const submitResponse = await submitResponseSet(responseSet);
        if (submitResponse.data.result.responseId) {
          // Delete item from local database
          deleteLocalResponse(responseSet.sessionId);
        }
      }
    }

    return 'submitted';
  }
);

export const loadOfflineResponsesAsync = createAsyncThunk<any, string, {state: RootState}>(
  'survey/loadOfflineResponses',
  async (arg, thunkApi) => {
    const localResponses = await getAllLocalResponses();
    return localResponses;
  }
);

export const loadAnimationAsync = createAsyncThunk<any, string, {state: RootState}>(
  'survey/loadAnimationAsync',
  async (arg, thunkApi) => {
    // console.log(`loadAnimationAsync ${arg}`);
    const animationResponse = await fetchAnimation(arg);
    return animationResponse?.data as any;
  }
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const questionSet = state.questionSets[state.currentSurveyId];
      const question = questionSet.questions[state.currentQuestionId];
      const newQuestion = getNextQuestion(questionSet, question);
      if (newQuestion) {
        state.newQuestionId = newQuestion.questionId;
      }
      else {
        state.newQuestionId = '_thankyou';
      }
    },
    decrement: (state) => {
      const questionSet = state.questionSets[state.currentSurveyId];
      const question = questionSet.questions[state.currentQuestionId];
      const newQuestion = getPreviousQuestion(questionSet, question);
      if (newQuestion) {
        state.newQuestionId = newQuestion.questionId;
      }
    },
    setCurrentZipCode: (state, action: PayloadAction<string>) => {
      state.currentZipCode = action.payload;
    },
    setCurrentSurveyId: (state, action: PayloadAction<string>) => {
      state.currentSurveyId = action.payload;
    },
    setCurrentLanguage: (state, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload;
    },
    setCurrentQuestionId: (state, action: PayloadAction<string>) => {
      state.currentQuestionId = action.payload;

      const block =  getBlockForQuestion(state, action.payload);
      state.currentBlockId = block?.blockId ?? '';
    },
    setNewQuestionId: (state, action: PayloadAction<string>) => {
      state.newQuestionId = action.payload;
    },
    setLayoutType: (state, action: PayloadAction<LayoutType>) => {
      state.layoutType = action.payload;
    },
    setSurveyResponse: {
      reducer: (state, action: PayloadAction<ResponsePayload>) => {
        const { response, questionId, choiceId, replaceResponse } = action.payload;
        const sessionId = state.currentSessionId;
        const questionSet = state.questionSets[state.currentSurveyId];
        const question = questionSet?.questions[questionId];

        if (!state.responseSets[sessionId]) {
          return;
        }
        const responseSet = state.responseSets[sessionId];
        let newValue = response;

        // these are the direct values used by the UI
        if (choiceId) {
          // append underscore and choice id (value) if specified
          const responseKey = `${questionId}_${choiceId}`;
          responseSet.responses[responseKey] = response;
        }
        else if (question.validationSettings?.type === 'MultipleValues' && responseSet.responses[questionId]) {
          const list = responseSet.responses[questionId];
          const tokens = list.split(',');
          const tokenIndex = tokens.indexOf(response);
          
          if (replaceResponse) {
            // special case for questions that have a 'prefer not to answer' option
            // ignore the previous values
            responseSet.responses[questionId] = newValue;
          }
          else if (tokenIndex === -1) {
            // append value
            newValue = list + `,${response}`;
            responseSet.responses[questionId] = newValue;
          }
          else {
            // currently in the list, remove it
            tokens.splice(tokenIndex, 1);
            responseSet.responses[questionId] = tokens.join(',');
          }
        }
        else {
          responseSet.responses[questionId] = response;
        }

        // these are the values used by Qualtrics
        setQualtricsResponse(responseSet.qualtricsResponses, question, newValue, choiceId);
      },
      prepare: (response: string, questionId: string, choiceId?: string, replaceResponse?: boolean) => {
        return {
          payload: { response, questionId, choiceId, replaceResponse },
        }
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadSurveyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadSurveyAsync.fulfilled, (state, action) => {
        const surveyData = action.payload;
        state.status = 'loaded';
        state.currentSurveyId = surveyData.options.surveyId;
        if (surveyData.options.language) {
          state.currentLanguage = surveyData.options.language;
        }

        // similar to startSurveyAsync, but doesn't start session
        if (surveyData.survey) {
          const blocks = loadBlocksFromSurvey(surveyData.survey);
          state.blocks = blocks;

          state.questionSets[state.currentSurveyId] = 
            loadQuestionSetFromSurvey(state.currentSurveyId, surveyData.survey, blocks);
        }
      })
      .addCase(loadSurveyAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(startSurveyAsync.pending, (state) => {
        state.status = 'loading';
         // reset state
        state.currentQuestionId = '';
        state.newQuestionId = '';
      })
      .addCase(startSurveyAsync.fulfilled, (state, action) => {
        const surveyData = action.payload;
        if (surveyData.errorMessage) {
          state.status = 'failed';
          state.errorMessage = surveyData.errorMessage;
          if (surveyData.errorMessage.toLowerCase().includes('quota')) {
            state.newQuestionId = "_quota";
          } else {
            state.newQuestionId = "_error";
          }
          return;
        }
        state.status = 'loaded';
        state.currentSurveyId = surveyData.options.surveyId;
        state.errorMessage = undefined;
        state.useProxyServer = surveyData.useProxyServer;
        if (surveyData.options.language) {
          state.currentLanguage = surveyData.options.language;
        }
        if (surveyData.sessionId) {
          state.currentSessionId = surveyData.sessionId;
        }
        const sessionId = state.currentSessionId;
        const clientData = getSurveyClientData(state.currentSurveyId);
        state.clientData = clientData;
        let questionSet: SurveyQuestionSet|undefined;
        
        if (surveyData.survey) {
          const blocks = loadBlocksFromSurvey(surveyData.survey);
          state.blocks = blocks;

          questionSet = loadQuestionSetFromSurvey(state.currentSurveyId, surveyData.survey, blocks);
          state.questionSets[state.currentSurveyId] = questionSet;
        }
        else {
          // previously loaded
          questionSet = state.questionSets[surveyData.options.surveyId];
        }

        const startQuestionId = surveyData.options.questionId ?? surveyData.session?.questions[0].questionId;
        if (startQuestionId) {
          state.currentQuestionId = startQuestionId;

          const block =  getBlockForQuestion(state, startQuestionId);
          state.currentBlockId = block?.blockId ?? '';
        }

        if (sessionId && !state.responseSets[sessionId]) {
          if (surveyData.sessionResponseSet) {
            state.responseSets[sessionId] = surveyData.sessionResponseSet;
          }
          else {
            // initialize response set
            const responseSet = createResponseSet(state.currentSurveyId, sessionId, surveyData.qualtricsSession, state.currentLanguage);
            
            // initialize responses for consent, zip code
            if (questionSet && clientData.consentQuestionId) {
              const consentQuestion = questionSet.questions[clientData.consentQuestionId];
              responseSet.responses[clientData.consentQuestionId] = '1';
              setQualtricsResponse(responseSet.qualtricsResponses, consentQuestion, '1');
            }
            if (questionSet && clientData.zipQuestionId && state.currentZipCode) {
              const zipQuestion = questionSet.questions[clientData.zipQuestionId];
              const zipChoice = zipQuestion?.choices?.find(choice => choice.display === state.currentZipCode);
              if (zipChoice) {
                responseSet.responses[clientData.zipQuestionId] = zipChoice.value;
                setQualtricsResponse(responseSet.qualtricsResponses, zipQuestion, zipChoice.value);
              }
            }

            state.responseSets[sessionId] = responseSet;
          }
        }
      })
      .addCase(startSurveyAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(submitResponsesAsync.pending, (state) => {
        state.responseStatus = 'loading';
      })
      .addCase(submitResponsesAsync.fulfilled, (state, action) => {
        const { status, direction, responseId, nextQuestionId } = action.payload;
        const sessionId = state.currentSessionId;

        if (status === 'submitted') {
          state.responseSets[sessionId].status = status;
          state.responseSets[sessionId].responseId = responseId;  
        }
        else if (status === 'completed') {
          // TODO: this probably indicates that the request to Qualtrics
          // returned, but wasn't successful, or the user is offline.
          // Continue, but leave status as 'completed' to allow for 
          // offline submission
          state.responseSets[sessionId].status = status;
        }
       
        if (nextQuestionId) {
          // this is the ID from qualtrics
          state.responseSets[sessionId].nextQualtricsQuestionId = nextQuestionId;
        }
    
        // go to next question (use our own logic)
        const questionSet = state.questionSets[state.currentSurveyId];
        const question = questionSet.questions[state.currentQuestionId];
        let newQuestion: SurveyQuestion|undefined = undefined;
        if (direction === 'forward') {
          newQuestion = getNextQuestion(questionSet, question);
        } else if (direction === 'back') {
          newQuestion = getPreviousQuestion(questionSet, question);
        }

        if (newQuestion) {
          state.newQuestionId = newQuestion.questionId;
        }
        else if (status === 'submitted') {
          state.newQuestionId = '_thankyou';
        }
        
        state.responseStatus = 'idle';
      })
      .addCase(submitResponsesAsync.rejected, (state, action) => {
        state.errorMessage = action?.error?.message ?? 'Error occurred while recording responses.';
        console.error(`submitResponses.rejected: ${state.errorMessage}`);
        const sessionId = state.currentSessionId;
        const responseSet = state.responseSets[sessionId]
        if (responseSet) {
          responseSet.status = 'started';

          // clear qualtrics session ID, proceed without submitted per block
          responseSet.qualtricsSession = '';
        }

        state.responseStatus = 'failed';
        state.newQuestionId = '_error';
      })
      .addCase(loadOfflineResponsesAsync.pending, (state) => {
      })
      .addCase(loadOfflineResponsesAsync.fulfilled, (state, action) => {
        const offlineResponseSets = action.payload;
        if (offlineResponseSets) {
          for (const responseSet of offlineResponseSets) {
            if (responseSet.sessionId) {
              state.responseSets[responseSet.sessionId] = responseSet;
            }
          }
        }
      })
      .addCase(loadOfflineResponsesAsync.rejected, (state) => {
      })
      .addCase(submitOfflineResponsesAsync.fulfilled, (state, action) => {
        // const status = action.payload;
      })
      .addCase(loadAnimationAsync.pending, (state, action) => {
        state.animationStatus[action.meta.arg] = 'loading';
      })
      .addCase(loadAnimationAsync.fulfilled, (state, action) => {
        const animationData = action.payload;
        if (animationData) {
          animationCache.set(action.meta.arg, animationData);
          state.animationStatus[action.meta.arg] = 'loaded';
        }
      })
      .addCase(loadAnimationAsync.rejected, (state, action) => {
        state.animationStatus[action.meta.arg] = 'failed';
      });
  },
});

export const { increment, decrement, 
  setCurrentQuestionId, setCurrentLanguage, setCurrentSurveyId, setCurrentZipCode,
  setNewQuestionId, setSurveyResponse, setLayoutType } = surveySlice.actions;

// The functions below are called selectors and allow us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrentLanguage = (state: RootState) => state.survey.currentLanguage;
export const selectZipCode = (state: RootState) => state.survey.currentZipCode;
export const selectCurrentSurveyId = (state: RootState) => state.survey.currentSurveyId;
export const selectCurrentSessionId = (state: RootState) => state.survey.currentSessionId;
export const selectCurrentBlockId= (state: RootState) => state.survey.currentBlockId;
export const selectCurrentQuestionId= (state: RootState) => state.survey.currentQuestionId;
export const selectSurveyStatus = (state: RootState) => state.survey.status;
export const selectNewQuestionId = (state: RootState) => state.survey.newQuestionId;
export const selectClientData = (state: RootState) => state.survey.clientData;
export const selectErrorMessage = (state: RootState) => state.survey.errorMessage;

export const selectCurrentQuestionSet = (state: RootState) => state.survey.questionSets[state.survey.currentSurveyId];
export const selectCurrentQuestions = createSelector(
  selectCurrentQuestionSet,
  (questionSet: SurveyQuestionSet|undefined) => questionSet?.questions,
)
export const selectBlockCount = (state: RootState) => Object.values(state.survey.blocks).length;
export const selectQuestionCount = createSelector(
  selectCurrentQuestionSet,
  (questionSet: SurveyQuestionSet|undefined) => questionSet?.questionOrder.length ?? 0,
)
export const selectCurrentBlock = (state: RootState) => state.survey.blocks[state.survey.currentBlockId];
export const selectCurrentQuestion = createSelector(
  selectCurrentQuestionSet,
  selectCurrentQuestionId,
  (questionSet: SurveyQuestionSet|undefined, questionId: string) => {
    if (questionSet && questionId) {
      return questionSet.questions[questionId];
    }

    return undefined;
  },
);
export const selectQuestionClientData = createSelector(
  selectCurrentQuestion,
  selectClientData,
  (question: SurveyQuestion|undefined, clientData: SurveyClientData) => {
    if (question && clientData) {
      return clientData.questionData[question.questionId];
    }

    return undefined;
  },
);

// type guard for following selector
const isSurveyQuestion = (item: SurveyQuestion | undefined): item is SurveyQuestion => {
  return !!item
}

export const selectBlockQuestions = createSelector(
  selectCurrentBlock,
  selectCurrentQuestionSet,
  (block: SurveyBlock, questionSet: SurveyQuestionSet|undefined) => {
    return block.questionIds
      .map(id => questionSet?.questions[id])
      .filter(isSurveyQuestion);
  },
)

export const selectCurrentIndex = createSelector(
  selectCurrentQuestionSet,
  selectCurrentQuestion,
  (questionSet: SurveyQuestionSet|undefined, question: SurveyQuestion|undefined) => {
    if (questionSet && question) {
      return getIndexOfQuestion(questionSet, question);
    }

    return undefined;
  },
);

export const selectCurrentResponseSet = (state: RootState) => state.survey.responseSets[state.survey.currentSessionId];
export const selectUnsubmittedResponses = (state: RootState) => {
  return Object.values(state.survey.responseSets).filter((responseSet) => responseSet.status === 'completed');
}

export const selectHasUnansweredQuestionInBlock = createSelector(
  selectBlockQuestions,
  selectCurrentResponseSet,
  (questions, responseSet) => {
    return hasUnansweredQuestions(questions, responseSet);
  },
)

export const selectCurrentResponse = createSelector(
  selectCurrentResponseSet,
  selectCurrentQuestion,
  (responseSet: SurveyResponseSet|undefined, question: SurveyQuestion|undefined) =>
    responseSet && question ? responseSet.responses[question.questionId] : undefined,
)
export const selectResponseSubmitted = createSelector(
  selectCurrentResponseSet,
  (responseSet: SurveyResponseSet|undefined) =>
    responseSet && responseSet.status === 'submitted',
)
 
export const selectLocaleStrings = (state: RootState) => state.survey.localeStrings[state.survey.currentLanguage];
export const selectAnimationStatus = (state: RootState) => state.survey.animationStatus;
export const selectLayoutType = (state: RootState) => state.survey.layoutType;
export const selectHasPhoneLayout = (state: RootState) => (state.survey.layoutType === 'phone');
export const selectHasTabletLayout = (state: RootState) => (state.survey.layoutType === 'tablet');
export const selectHasDesktopLayout = (state: RootState) => (state.survey.layoutType === 'desktop');

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectQuestionCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  }; */

export default surveySlice.reducer;
