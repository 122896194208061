import React, { useState, useEffect, useMemo } from 'react';
import Lottie from "lottie-react";
import { useNavigate } from 'react-router-dom';
import { NavigationControl } from '../../../components/NavigationControl';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  increment,
  loadAnimationAsync,
  setSurveyResponse,
  selectClientData,
  selectCurrentLanguage,
  selectAnimationStatus,
  selectLocaleStrings,
  selectHasUnansweredQuestionInBlock,
  selectHasPhoneLayout,
  selectLayoutType,
  submitResponsesAsync,
  selectCurrentQuestionSet,
} from '../surveySlice';
import { NavigationProps, QuestionViewProps } from './types';
import { QuestionChoice, SurveyDirection } from '../types';
import { animationCache, getAnimationPath, getQuestionText, getNextQuestionId, hasValidation, isFirstInBlock, isLastInBlock } from '../utils';
import { QuestionHeader } from './QuestionHeader';
import { TextInput } from './TextInput';
import { MultipleChoice } from './MultipleChoice';
import { StaticText } from './StaticText';
import { Summary } from './Summary';
import { ArtsParticipation } from './ArtsParticipation';
import { ZipCode } from './ZipCode';
import { SUBMIT_RESPONSES_BY_BLOCK } from '../../../constants';
import { AppHeader } from '../../../components/AppHeader';

import styles from './QuestionView.module.scss';

export const QuestionView = (props : QuestionViewProps) => {
  const { block, clientData, question, response } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [finishedSection, setFinishedSection] = useState(false);
  const [navEnabled, setNavEnabled] = useState<boolean>(true);
  const [validated, setValidated] = useState<boolean>(true);
  const [textEntryChoice, setTextEntryChoice] = useState<QuestionChoice|undefined>(undefined);
  const [animation, setAnimation] = useState<any|undefined>(undefined);

  const lastInBlock = block && question ? isLastInBlock(block, question) : false;
  const firstInBlock = block && question ? isFirstInBlock(block, question) : false;
  const surveyClientData = useAppSelector(selectClientData);
  let enablePrevious = (!firstInBlock || !SUBMIT_RESPONSES_BY_BLOCK) && (surveyClientData.allowBackButton !== false);
  if (!!textEntryChoice) {
    enablePrevious = true;
  }
  const enableNext = true;
  const localeStrings = useAppSelector(selectLocaleStrings);
  const language = useAppSelector(selectCurrentLanguage);
  const questionSet = useAppSelector(selectCurrentQuestionSet);
  const hasUnansweredQuestions = useAppSelector(selectHasUnansweredQuestionInBlock);
  const layoutType = useAppSelector(selectLayoutType);
  const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
  const hasDesktopLayout = layoutType === 'desktop';
  const blockTitle = block?.name ? localeStrings[block.name] : block?.description;
  const sectionName = question?.clientData?.section ?? block?.name ?? 'unknown';
  const participationAnimationFiles = useMemo(() => ['', 'S1-dancer.json', 'S1-writer.json', 'S1-media.json', 'S1-music.json', 'S1-theater.json', 'S1-artist.json'], []);
  const animationStatus = useAppSelector(selectAnimationStatus);
  let animationFile = (finishedSection && hasUnansweredQuestions && hasPhoneLayout ? undefined : question?.clientData?.animation);
  
  if (question.questionId === surveyClientData.participationIntroId && step > 0) {
    // in Arts Participation, use additional animations for steps 1+
    animationFile = participationAnimationFiles[step];
  }
  animationFile = getAnimationPath(animationFile, hasPhoneLayout);

  useEffect(() => {
    // always clear 'finished' when question changes
    setFinishedSection(false);

    // scroll back to top
    window.scrollTo(0, 0);

    // clear animation
    setAnimation(undefined);
    
    // clear custom text entry 
    setTextEntryChoice(undefined);

    // reset validation
    setValidated(!hasValidation(question, surveyClientData));

    // don't let user skip right away
    if (question.clientData?.noDelay !== true) {
      setNavEnabled(false);
    }
    const timerId = setTimeout(() => {
      setNavEnabled(true);
    }, 1000);

    return () => clearInterval(timerId);
  }, [question, surveyClientData]);

  useEffect(() => {
      if (animationFile) {
        const loadStatus = animationStatus[animationFile];
        if (loadStatus === 'loaded') {
          const newAnimation = animationCache.get(animationFile);
          if (animation !== newAnimation) {
            setAnimation(animationCache.get(animationFile));
          }
        }
        else if (!loadStatus) {
          // load the animation
          dispatch(loadAnimationAsync(animationFile));
          setAnimation(undefined);
        }
      }
      else {
        setAnimation(undefined);
      }
  }, [animation, animationFile, animationStatus, dispatch]);

  useEffect(() => {
    // preload block animations
    if (block) {
      const lastQuestionId = block.questionIds.length > 0 ? block.questionIds[block.questionIds.length - 1] : '';

      for (const questionId of block.questionIds) {
        const questionPath = getAnimationPath(surveyClientData.questionData[questionId]?.animation, hasPhoneLayout);
        
        if (questionPath) {
          if (!animationStatus[questionPath]) {
            // not yet loaded or loading
            dispatch(loadAnimationAsync(questionPath));

            // load one at a time
            return;
          }
          else if (animationStatus[questionPath] === 'loading') {
            return;
          }
        }

        // for arts participation, also preload character animations
        if (questionId === surveyClientData.participationIntroId) {
          for (const filename of participationAnimationFiles) {
            const filepath = getAnimationPath(filename, hasPhoneLayout);

            if (filepath && !animationStatus[filepath]) {
                // not yet loaded or loading
                dispatch(loadAnimationAsync(filepath));

                // load one at a time
                return;
            }
          }
        }
      }

      // Add first animation of the next block
      if (lastQuestionId) {
        const nextQuestionId = getNextQuestionId(questionSet, lastQuestionId);
        if (nextQuestionId) {
          const questionPath = getAnimationPath(surveyClientData.questionData[nextQuestionId]?.animation, hasPhoneLayout);
          if (questionPath && !animationStatus[questionPath]) {
            // not yet loaded or loading
            dispatch(loadAnimationAsync(questionPath));
          }
        }
      }
    }
  }, [block, questionSet, hasPhoneLayout, dispatch, animationStatus, 
      surveyClientData.questionData, 
      surveyClientData.participationIntroId, 
      participationAnimationFiles]);

  function handleQuestionResponse(value: string, choice?: string) {
    // certain multiple-choice questions have values that should be 'exclusive', e.g. ones
    // that say 'prefer not to answer'
    const replaceResponse = clientData?.exclusiveChoices && 
      (clientData.exclusiveChoices.includes(value) || clientData.exclusiveChoices.includes(response));

    // look for links in the question
    // (used to exit out of AP survey with a "no" response for arts participation)
    const links = clientData?.links;
    if (links) {
       const link = links[value];
       if (link) {
         navigate(link);
       }
    }

    dispatch(setSurveyResponse(value, question.questionId, choice, replaceResponse));
  }

  function validateZip(value: string) {
    // set value for consent question to "yes" here too
    const consentQuestionId = surveyClientData.consentQuestionId;
    if (consentQuestionId) {
      dispatch(setSurveyResponse('1', consentQuestionId));
    }
    
    if (question.questionType === 'TE') {
      handleQuestionResponse(value);

      if (value.length >= 5) {
        setValidated(true);
      }
    }
    else {
      const zipChoice = question.choices?.find(choice => choice.display === value);
      if (zipChoice) {
          handleQuestionResponse(zipChoice.value);
      }
    }
   
    // have to run both functions for zip code screen
    // handleFinished();
  }

  const submitAndNext = (defaultDelay: number) => {
    let delay = defaultDelay;
    let submitBlock = false;
    
    let direction: SurveyDirection | undefined;
    if (question.questionId === surveyClientData.lastQuestionId) {
      // go to thank you screen at the end
      direction = 'finish';
      submitBlock = true;
    }
    else {
      if (surveyClientData.displaySectionFinished === false) {
        direction = 'forward';
      }
  
      if (lastInBlock) {
        if (surveyClientData.displaySectionFinished !== false) {
          // go to summary
          setFinishedSection(true);
  
          // scroll back to top
          window.scrollTo(0, 0);
        }
        else if (SUBMIT_RESPONSES_BY_BLOCK) {
          // submit responses now
          submitBlock = true;
        }
      }
    }

    if (lastInBlock && SUBMIT_RESPONSES_BY_BLOCK) {
      // don't let user submit again
      setNavEnabled(false);

      // submit answers, only advance if direction set
      dispatch(submitResponsesAsync({
        questionId: question.questionId,
        direction,
        submitBlock
      }));
    }
    else {
      // Speed testing by going to next question automatically
      setTimeout(() => {
        dispatch(submitResponsesAsync({
          questionId: question.questionId, 
          direction: 'forward',
        }));
        setFinishedSection(false);
      }, delay);
    }
  }

  const handleFinished = () => {
    // don't let user submit again
    setNavEnabled(false);

    submitAndNext(500);
  }

  const handleValidated = (valid: boolean) => {
    setValidated(!hasValidation(question, surveyClientData) || valid);
  }

  const handleMCTextEntryChoice = (textEntryChoice: QuestionChoice|undefined) => {
    setTextEntryChoice(textEntryChoice);
  }

  const handlePrevious = () => {
    if (hasValidation(question, surveyClientData) && !validated) {
      return;
    }
    if (textEntryChoice) {
      setTextEntryChoice(undefined);
      return;
    }

    dispatch(submitResponsesAsync({
        questionId: question.questionId, 
        direction: 'back',
      }));
  }

  const handleNext = () => {
    if (hasValidation(question, surveyClientData) && !validated) {
      return;
    }
    if (finishedSection) {
      // already submitted by Summary component, go to next question
      dispatch(increment());
      return;
    }
    
    submitAndNext(50);
  }

  // for questions with multiple screens
  const handlePreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);

      // scroll back to top
      window.scrollTo(0, 0);
    }
    else {
      handlePrevious();
    }
  }

  // for questions with multiple screens
  const handleNextStep = () => {
    // 7 steps in Arts participation section
    if (step < 6) {
      setStep(step + 1);

      // scroll back to top
      window.scrollTo(0, 0);
    }
    else {
      handleNext();
    }
  }

  // get background image from client data
  const background = clientData && clientData.bgFileType !== 'json' ? clientData.bgFile : undefined;
  const alignment = question.questionType === 'MC' && clientData && clientData.alignment ? `valign-${clientData.alignment}` : '';
  
  const navigationProps: NavigationProps = {
    enabled: navEnabled,
    showPrevious: enablePrevious,
    showNext: enableNext,
    onPrevious: handlePrevious,
    onNext: handleNext,
  };

  // special cases
  if (question.questionId === surveyClientData.zipQuestionId) {
    const zipText = getQuestionText(question, language);
    // Zip code
    return (
      <ZipCode questionText={zipText} contentType={question.clientData?.contentType}
        validated={validated} alwaysAnimateBg={true}
        onValidate={validateZip} onNext={handleNext} />
    );
  }
  else if (question.questionId === surveyClientData.participationIntroId) {
    // Arts participation
    const apNavigationProps: NavigationProps = {
      enabled: true,
      showPrevious: step > 0,
      showNext: enableNext,
      onPrevious: handlePreviousStep,
      onNext: handleNextStep,
    };

    return (
      <>
        <div className={ `background-container ${styles.bg}` }>
          { animation && <div className="lottie-container">
              <Lottie className="lottie" animationData={animation} loop={step === 0} />
          </div> }
        </div>
        <div className={`section-${block?.name ?? 'unknown'} ${styles.questionParent}`}>
          <QuestionHeader title={blockTitle} question={question}
            navEnabled={navEnabled}
            showPrevious={step > 0} showNext={enableNext}
            onPrevious={handlePreviousStep} onNext={handleNextStep} />
          <ArtsParticipation step={step} navigationProps={apNavigationProps} {...props} />
        </div>
      </>);
  }

  // branch by type
  return (
    <>
      { background ? 
        <div className={ `background-container ${styles.bg}` }
          style={{ 'backgroundImage': `url(${background})`}}></div> :
        <div className={ `background-container ${styles.bg}` }>
          { animation && <div className="lottie-container">
                <Lottie className="lottie" animationData={animation} loop={true} />
                { sectionName === 'intro' && <div className='frame-overlay'></div> }
              </div> }
         </div> }
      <div className={`section-${sectionName} ${styles.questionParent}`}>
        { hasDesktopLayout && !finishedSection && 
                <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
                      enabled={navigationProps?.enabled ?? true}
                      showPrevious={navigationProps?.showPrevious ?? true} 
                      showNext={navigationProps?.showNext ?? true}
                      onPrevious={navigationProps?.onPrevious}
                      onNext={navigationProps?.onNext}
                      /> }
        { question.clientData?.useSurveyHeader ?
          <AppHeader /> :
          <QuestionHeader key={question.questionId}
              title={blockTitle} question={finishedSection ? undefined : question}
              sectionClass={`section-${sectionName}`} 
              navEnabled={navEnabled && validated}
              showPrevious={enablePrevious} showNext={enableNext} 
              onPrevious={handlePrevious} onNext={handleNext} /> }
        { finishedSection && <div className={`flex-fit`}>
              <Summary navigationProps={navigationProps} {...props} />
            </div> }
        { !finishedSection && <div className={`flex-fit ${alignment}`}>
            { question.questionType === 'TE' && <TextInput key={question.questionId}
              onChange={handleQuestionResponse}
              onValidated={handleValidated}
              onFinished={handleFinished}
              layoutType={layoutType}
              navigationProps={navigationProps}
              {...props}
            /> }
            { question.questionType === 'MC' && <MultipleChoice key={question.questionId}
              onChange={handleQuestionResponse}
              onFinished={handleFinished}
              onTextEntryChoice={handleMCTextEntryChoice}
              alignment={hasPhoneLayout ? clientData?.alignment : 'top'}
              layoutType={layoutType}
              textEntryChoice={textEntryChoice}
              navigationProps={navigationProps} 
              {...props} />
            }
            { question.questionType === 'DB' && 
                <StaticText layoutType={layoutType} 
                  navigationProps={navigationProps}
                  {...props} /> }
        </div> }
        <div className='margin-bottom' />
      </div>
    </>
  )
}
