import React from 'react';
import styles from './NavigationControl.module.scss';
import { LeftArrow } from './LeftArrow';
import { RightArrow } from './RightArrow';
import {
  selectLocaleStrings,
} from '../features/survey/surveySlice';
import { useAppSelector } from '../app/hooks';

export type NavigationControlProps = {
    enabled?: boolean;
    showPrevious?: boolean;
    showNext?: boolean;
    sectionClass?: string,
    labels?: string[],
    onPrevious?: () => void;
    onNext?: () => void;
  };

export const NavigationControl = (props : NavigationControlProps) => {
    const { enabled, labels, showPrevious, showNext } = props;
    const sectionClass = props.sectionClass ?? 'nav';
    const isDisabled = (enabled === false);
    const localeStrings = useAppSelector(selectLocaleStrings);

    return (
      <div className={`${sectionClass} ${styles.container}`}>
        <div>
          <button
            disabled={isDisabled}
            className={`nav-button ${showPrevious ? '' : 'hidden'}`}
            aria-label={ localeStrings['back'] }
            onClick={props.onPrevious}
            >
              <LeftArrow />
            </button></div>
        <div>
        { labels && labels.map( label => <div key={label} className='nav-label'>{ label }</div>) }
        </div>
        <div>
            <button
            disabled={isDisabled}
            className={`nav-button ${showNext ? '' : 'hidden'}`}
            aria-label={ localeStrings['next'] }
            onClick={props.onNext}
            ><RightArrow />
            </button></div>
    </div>
    );
  }
