import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import './App.css';
import './css/onop.scss'
import { DESKTOP_BREAKPOINT, PHONE_BREAKPOINT } from './constants';

import {
  Outlet,
  useLocation
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from './app/hooks';

import {
  selectCurrentLanguage,
  setLayoutType,
} from './features/survey/surveySlice';

function App() {
  const location = useLocation();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // report each page change to Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  // Register event listeners for when media query changes to/from phone layout
  useEffect(() => {
    function setPhoneLayout(e: MediaQueryListEvent) {
      if (e.matches) {
        dispatch(setLayoutType('phone'));
      }
    }
    function setTabletLayout(e: MediaQueryListEvent) {
      if (e.matches) {
        dispatch(setLayoutType('tablet'));
      }
    }
    function setDesktopLayout(e: MediaQueryListEvent) {
      if (e.matches) {
        dispatch(setLayoutType('desktop'));
      }
    }
    
    const phoneMq = window.matchMedia(`(max-width: ${PHONE_BREAKPOINT-1}px)`);
    phoneMq.addEventListener('change', setPhoneLayout);
    if (phoneMq.matches) {
      dispatch(setLayoutType('phone'));
    }
    const tabletMq = window.matchMedia(`(min-width: ${PHONE_BREAKPOINT}px) and (max-width: ${DESKTOP_BREAKPOINT-1}px)`);
    tabletMq.addEventListener('change', setTabletLayout);
    if (tabletMq.matches) {
      dispatch(setLayoutType('tablet'));
    }
    const desktopMq = window.matchMedia(`(min-width: ${DESKTOP_BREAKPOINT}px)`);
    desktopMq.addEventListener('change', setDesktopLayout);
    if (desktopMq.matches) {
      dispatch(setLayoutType('desktop'));
    }

    // cleanup if we unmount
    return () => {
      phoneMq.removeEventListener('change', setPhoneLayout);
      tabletMq.removeEventListener('change', setTabletLayout);
      desktopMq.removeEventListener('change', setDesktopLayout);
    }
  }, [dispatch]);

/*
  let [online, isOnline] = useState(navigator.onLine);
  const setOnline = () => {
    console.log('We are online!');
    isOnline(true);
  };
  const setOffline = () => {
    console.log('We are offline!');
    isOnline(false);
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []);
 */

  return (
    <div className={`App language-${currentLanguage ?? 'en'}`}>
      <Outlet />
    </div>
  );
}

export default App;
