import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './Summary.module.scss';

import {
    selectBlockQuestions,
    selectClientData,
    selectCurrentResponseSet,
    selectLocaleStrings,
    setSurveyResponse,
    submitResponsesAsync
  } from '../surveySlice';
import { getQuestionChoices, getQuestionText, separateAnsweredQuestions } from '../utils';
import { QuestionChildProps } from './types';
import { SurveyResponseSet } from '../types';

interface SummaryQuestionProps extends QuestionChildProps {
    responseSet: SurveyResponseSet;
    selectedQuestionId?: string;
}

const UnansweredQuestion = (props: SummaryQuestionProps) => {
    const {question, language, response, selectedQuestionId, onChange, onFinished} = props;
    let choices = getQuestionChoices(question, language);
    const inputType = question.clientData?.inputType ?? 'text';
    const questionText = getQuestionText(question, language);
    const [value, setValue] = useState(response ?? '');
    const responseList = value.split(',');
    if (question.clientData?.choicesReversed) {
        choices = [...choices].reverse();
    }

    return (<div className="unanswered-question-container">
                <div className="unanswered-question">{ questionText }</div>
                { question.questionId === selectedQuestionId && question.questionType === 'TE' && <div className='centered'>
                    <input
                    className="text-input text-input-3"
                    autoFocus
                    type={inputType}
                    aria-label={questionText}
                    value={value}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            // TODO: validate
                            if (onFinished) {
                                onFinished(value);
                            }
                        }
                    }}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if (onChange) {
                            onChange(e.target.value);
                        }
                    }}
                />
                </div> }
                { question.questionId === selectedQuestionId && question.questionType === 'MC' && 
                    <div className="choice-column">
                    { choices.map((choice, i) =>
                        <div key={`${question.questionId}-${i}`} id={`${i}`}
                          className={`onop-button ${question.clientData?.buttonClass ?? ''} ${ responseList.includes(`${choice.value}`) ? 'selected' : ''} `}
                          onClick={(e) => {
                            e.stopPropagation();

                            if (onChange) {
                                onChange(choice.value);
                            }

                            if (onFinished) {
                                onFinished(choice.value);
                            }
                          }}>{choice.display}</div>) }
                  </div>
                }
            </div>);
}

const AnsweredQuestion = (props: SummaryQuestionProps) => {
    const {language, question, responseSet} = props;
    const questionText = getQuestionText(question, language);
    let displayText = responseSet.responses[question.questionId] ?? '';

    if (question.questionType === 'MC') {
        const choices = getQuestionChoices(question, language);
        const answerChoice = choices.find((choice) => choice.value === displayText);
        if (answerChoice) {
            displayText = answerChoice.display;
        }
    }

    return (<div className={`${styles.answeredQuestion} bottom-border-dark`}>
            <div className={styles.question}>{ questionText }</div>
            <div className={styles.response}>{ displayText }</div>
        </div>);
}

export const Summary = (props : QuestionChildProps) => {
    const {block, question, navigationProps} = props;
    const [selectedQuestionId, setSelectedQuestionId] = useState<string|undefined>(undefined);
    const localeStrings = useAppSelector(selectLocaleStrings);
    const surveyClientData = useAppSelector(selectClientData);
    const blockQuestions = useAppSelector(selectBlockQuestions);
    const responseSet = useAppSelector(selectCurrentResponseSet);
    const sectionCount = surveyClientData.sectionOrder.length;
    const { answered, unanswered } = separateAnsweredQuestions(blockQuestions, responseSet);
    const response = selectedQuestionId ? responseSet.responses[selectedQuestionId] : '';
    const [currentResponse, setCurrentResponse] = useState<string|undefined>(response);
    const [ startUnansweredCount, ] = useState<number>(unanswered.length);
    const dispatch = useAppDispatch();
           
    const index = block?.sectionIndex ?? 0;
    const lastSection = index >= sectionCount - 1;
    let continueLabel: string;
    let message: string;
    let order = getBlockOrder(index);
    
    function getBlockOrder(index: number): string {
        const strings = ['1st', '2nd', '3rd', '4th', '5th', '6th'];
        return (index >= 0 && index < strings.length ? strings[index] : '');
    }

    function handleSubmit(): void {
        if (startUnansweredCount > 0) {
            submitQuestionResponse();
        }

        dispatch(submitResponsesAsync({
            questionId: question.questionId, 
            submitBlock: true,
        }));

        if (navigationProps?.onNext) {
            navigationProps.onNext();
        }
    }

    function selectQuestion(questionId: string): void {
        if (selectedQuestionId) {
            submitQuestionResponse();
        }
        setSelectedQuestionId(selectedQuestionId === questionId ? '' : questionId);
    }

    function handleQuestionResponse(value: string, choice?: string): void {
        setCurrentResponse(value);
    }

    function submitQuestionResponse(value?: string): void {
        const response = value ?? currentResponse;
        if (selectedQuestionId && response) {
            dispatch(setSurveyResponse(response, selectedQuestionId));
        }
    }

    // if there were unanswered questions at the start
    // don't show summary for last section (demographics)
    if (unanswered.length > 0 && !lastSection) {
       continueLabel = localeStrings['submitAnswers'];

       return (
            <div className={`${styles.summaryContainer} ${styles.unfinishedContainer}`}>
                <div className="scroll-y">
                    { unanswered.length > 0 && <div className={styles.heading}>
                        { localeStrings['unanswered'] }
                    </div> }
                    { unanswered.length > 0 && <div className="secondary-text">
                        { localeStrings['unansweredQuestionPrompt'] }
                    </div> }
                    { unanswered.map((question) => 
                        <div className={`${styles.unansweredQuestion} bottom-border-dark`} key={question.questionId}
                            onClick={() => { selectQuestion(question.questionId); }}>
                            <UnansweredQuestion {...props} 
                                question={question} response={response} responseSet={responseSet} 
                                selectedQuestionId={selectedQuestionId}
                                onChange={handleQuestionResponse} onFinished={submitQuestionResponse} />
                        </div> )}
                    <div className={styles.answeredContainer}>
                    <div className={styles.heading}>
                        { localeStrings['answered'] }
                    </div>
                    { answered.map((question) => 
                        <AnsweredQuestion key={question.questionId} {...props} 
                            question={question} response={response} responseSet={responseSet} /> )}
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <button
                        className="onop-button onop-button-primary"
                        aria-label={ continueLabel }
                        onClick={handleSubmit}
                        >{ continueLabel }
                    </button>
                </div>
                <div className='margin-bottom' />
            </div>);
    }

    // Finished section
    if (!lastSection) {
        continueLabel = `${localeStrings['continueToSection']} ${ index + 2 }`;
        message = localeStrings['completedSection'].replace('%section%', order);
    }
    else {
        // last section
        continueLabel = localeStrings['continueToRewards'];
        message = localeStrings['completedAll'].replace('%section%', order);
    }
  
    return (
        <div className={styles.summaryContainer}>
            <div className="question-container text-container">
                <div className="main-subtitle completion-text">
                    { message }<br/>🎊
                </div>
                <div className="centered padding-double">
                    <button
                        className="onop-button onop-button-primary"
                        aria-label={ continueLabel }
                        onClick={handleSubmit}
                        >{ continueLabel }
                    </button>
                </div>
            </div>
        </div>);
}
