import React, { useEffect, useState } from 'react';

import { ADVANCE_ON_CHOICE_SELECTION } from '../../../constants';
import { getQuestionText, getQuestionChoices } from '../utils';
import { MultipleChoiceProps } from './types';
import { NavigationControl } from '../../../components/NavigationControl';
import { QuestionText } from './QuestionText';
import { ScrollArrow } from '../../../components/ScrollArrow';

export const MultipleChoice = (props : MultipleChoiceProps) => {
    const {block, question, clientData, language, response, responseSet, alignment, layoutType, textEntryChoice, navigationProps,
      onChange, onFinished, onTextEntryChoice} = props;
    const [reveal, setReveal] = useState(response.length > 0);
    const [textValue, setTextValue] = useState('');
    const questionText = getQuestionText(question, language);
    let choices = getQuestionChoices(question, language);
    const multipleValues = question.validationSettings?.type === 'MultipleValues';
    const hasDesktopLayout = layoutType === 'desktop';
    const responseList = response.split(',');
    const choiceLayout = (clientData?.columns ?? 0) > 1 ? 'choice-grid' : 'choice-column scroll-y';
    const secondaryText = clientData?.secondaryText && language ? clientData.secondaryText[language] : '';
    const textEntryPrompt = clientData?.textEntryPrompt && language ? clientData?.textEntryPrompt[language] : '';
    const navLabels = clientData?.navLabels && language && !textEntryChoice ? (clientData.navLabels[language] ?? []) : [];
    const navigationControl = <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
      enabled={navigationProps?.enabled ?? true}
      labels={ navLabels}
      showPrevious={ !!textEntryChoice || (navigationProps?.showPrevious ?? true)} 
      showNext={navigationProps?.showNext ?? true}
      onPrevious={navigationProps?.onPrevious}
      onNext={navigationProps?.onNext}
      />;
    const classForReveal = reveal || layoutType !== 'phone' ? '' : 'delay-reveal';
      
    useEffect(() => {
      if (clientData?.noDelay) {
        setReveal(true);
      }
    }, [clientData]);

    if (clientData?.choicesReversed) {
      choices = [...choices].reverse();
    }

    return (<div className='question-container'
              onClick={(e) => { setReveal(true); }}>
              { alignment !== 'bottom' && <div className="centered">
                <QuestionText contentType={clientData?.contentType ?? 'text'} questionText={questionText} />
                { secondaryText && !textEntryChoice && <div className="secondary-text">{secondaryText}</div> }</div> }
              { !hasDesktopLayout && alignment === 'bottom' && 
                <div className={classForReveal}>{ navigationControl }</div> }
              { hasDesktopLayout && navLabels.length > 0 && 
                  navLabels.map((label, i) => <div key={`label-${i}`} className='nav-label'>{label}</div>) }
              { textEntryChoice && <div className="flex-column-center self-describe-container">
                  <div>{textEntryPrompt || textEntryChoice.display}</div>
                  <input
                    className="text-input"
                    autoFocus
                    type="text"
                    aria-label={questionText}
                    value={textValue ?? ''}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            
                        }
                    }}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                      if (onChange) {
                        onChange(e.target.value, textEntryChoice.value);
                      }
                    }}
                  /></div>}
              { !textEntryChoice && <div className={`flex-column-single-space fit-y ${ classForReveal }`}>
                { !hasDesktopLayout && question.clientData?.scrollArrow && navigationControl }
                <div className="flex-row-center fit-y relative-container">
                  <div className={choiceLayout} >
                    { choices.map((choice, i) =>
                        <button key={`${question.questionId}-${i}`} id={`${i}`}
                          aria-label={choice.display}
                          className={`onop-button ${clientData?.buttonClass ?? ''} ${ responseList.includes(`${choice.value}`) ? 'selected' : ''} `}
                          onClick={(e) => {
                            e.stopPropagation();

                            setReveal(true);
                            if (onChange) {
                                onChange(choice.value);
                            }
                            if (choice.textEntry && !responseList.includes(choice.value)) {
                              setTextValue(responseSet ? responseSet.responses[`{question.questionId}_${choice.value}`] : '');
                              if (onTextEntryChoice) {
                                onTextEntryChoice(choice);
                              }
                            }
                            /* Do not advance automatically unless ADVANCE_ON_CHOICE_SELECTION */
                            if (ADVANCE_ON_CHOICE_SELECTION && onFinished && !multipleValues) {
                              onFinished(choice.value);
                            }
                          }}>{choice.display}</button>) }
                  </div>
                  { !hasDesktopLayout && question.clientData?.scrollArrow && <ScrollArrow /> }
                </div>
              </div> }
              { alignment === 'bottom' && <div className="question">
                  <QuestionText contentType={clientData?.contentType ?? 'text'} questionText={questionText} />
                </div> }
              <div className={`${ classForReveal } flex-no-shrink`}>
              { !hasDesktopLayout && alignment !== 'bottom' && (textEntryChoice || !question.clientData?.scrollArrow) &&
                  navigationControl }
              </div>
            </div>);
}
