import { useAppSelector } from '../../../app/hooks';
import styles from './ArtsParticipation.module.scss';
import { NavigationControl } from '../../../components/NavigationControl';

import {
    selectLocaleStrings,
    selectHasPhoneLayout,
    selectLayoutType,
} from '../surveySlice';

import { ArtsParticipationProps } from './types';
import { ScrollArrow } from "../../../components/ScrollArrow";

export const ArtsParticipation = (props : ArtsParticipationProps) => {
    const {block, step, navigationProps} = props;
    const localeStrings = useAppSelector(selectLocaleStrings);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const layoutType = useAppSelector(selectLayoutType);

    const index = step ?? 0;
    const textKeys = ['apIntro', 'apDance', 'apLiterary', 'apMedia', 'apMusic', 'apPerformance', 'apVisual'];
    const secondaryKeys = ['apIntro2', 'apDance2', 'apLiterary2', 'apMedia2', 'apMusic2', 'apPerformance2', 'apVisual2'];
    const secondaryText = localeStrings[secondaryKeys[index]];
    const classForReveal = hasPhoneLayout ? 'delay-reveal' : '';

    return (
        <div className="flex-column-no-gap text-container fit-y">
            <div key={index} className={`${styles.participation} z-middle question-container fit-y`}>    
                <div className={styles.intro} dangerouslySetInnerHTML={{__html: localeStrings[textKeys[index]]}}></div>
                { secondaryText && <div className={`flex-column-no-gap ${classForReveal} relative-container fit-y`}>
                    { index === 0 && layoutType !== 'desktop' && <div className="content-relative"><ScrollArrow /></div> }
                    <div className="scroll-y margin-right-2" dangerouslySetInnerHTML={{__html: secondaryText}}></div>
                    </div> }
                { hasPhoneLayout && <div className='flex-no-shrink delay-reveal'>
                    <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
                            enabled={navigationProps?.enabled ?? true}
                            showPrevious={navigationProps?.showPrevious ?? true} 
                            showNext={navigationProps?.showNext ?? true}
                            onPrevious={navigationProps?.onPrevious}
                            onNext={navigationProps?.onNext}
                            /></div> }
                </div>
            { hasPhoneLayout && <div className='margin-bottom' /> }
            { !hasPhoneLayout && <div className='flex-no-shrink'>
                <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
                        enabled={navigationProps?.enabled ?? true}
                        showPrevious={navigationProps?.showPrevious ?? true} 
                        showNext={navigationProps?.showNext ?? true}
                        onPrevious={navigationProps?.onPrevious}
                        onNext={navigationProps?.onNext}
                        /></div> }
        </div>
        );
}
