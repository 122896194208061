import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Lottie from "lottie-react";
import ReactGA from "react-ga4";

import { ACCESS_COOKIE_VALUE, REQUIRE_ACCESS_PASSWORD } from '../constants';
import { Layout } from '../layouts/Layout';
import {
  selectCurrentLanguage,
  selectLocaleStrings
} from '../features/survey/surveySlice';
import { fetchToken } from '../features/survey/surveyAPI';

import { useAppSelector } from '../app/hooks';
import styles from './Home.module.scss';
import animationJson from "../animations/TOC-hand.json";
import onopLogo from '../images/ONOP-logo.png';


export function HomeContent() {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const localeStrings = useAppSelector(selectLocaleStrings);
  const [cookies] = useCookies(['onop_access']);

  // special cases - insert line breaks
  const introText = localeStrings['homeSurveyIntro'].replace('Survey', `Survey\n`);
  const secondText = localeStrings['homeSurveyClose'].replace('characters,', `characters,\n`);

  useEffect(() => {
    // start loading token early
    if (navigator.onLine) {
      fetchToken();
    }
  }, []);

  function startSurvey(language: string): void {
    const isDevEnvironment = process.env.NODE_ENV === 'development';
    const hasAccess = !REQUIRE_ACCESS_PASSWORD || isDevEnvironment || (cookies.onop_access === ACCESS_COOKIE_VALUE);
    if (hasAccess) {
      ReactGA.event({
        category: 'toc',
        action: "start survey",
        label: currentLanguage,
      });

      navigate(`/draw`);
    }
    else {
      // until we launch, require user to have entered password and set up cookie
      window.location.assign(`/auth/access.php`);
    }
  }

  return (
      <>
        <header>
          <h2 className="centered">
              <img className={styles.logo} src={onopLogo} alt="One Nation/One Project" />
          </h2>
        </header>
        <div className={`section-intro ${styles['home-container']}`}>
          <div className="question-large phone-only">{ localeStrings['welcome'] }</div>
          <p className='pre-line'>{ introText }</p>
          <div>
            <table className={styles.toc}>
              <tbody>
                <tr>
                  <td><div className={`${styles['participation-chip']} ${styles['color-chip']}`}></div></td>
                  <th scope="row" className={styles.section}>{ localeStrings['participation']}</th>
                  <td>2 { localeStrings['questions']}</td>
                </tr>
                <tr>
                  <td><div className={`${styles['cohesion-chip']} ${styles['color-chip']}`}></div></td>
                  <th scope="row" className={styles.section}>{ localeStrings['cohesion']}</th>
                  <td>10 { localeStrings['questions']}</td>
                </tr>
                <tr>
                  <td><div className={`${styles['wellbeing-chip']} ${styles['color-chip']}`}></div></td>
                  <th scope="row" className={styles.section}>{ localeStrings['wellbeing']}</th>
                  <td>5 { localeStrings['questions']}</td>
                </tr>
                <tr>
                  <td><div className={`${styles['perceptions-chip']} ${styles['color-chip']}`}></div></td>
                  <th scope="row" className={styles.section}>{ localeStrings['perceptions']}</th>
                  <td>2 { localeStrings['questions']}</td>
                </tr>
                <tr>
                  <td><div className={`${styles['demographics-chip']} ${styles['color-chip']}`}></div></td>
                  <th scope="row" className={styles.section}>{ localeStrings['demographics']}</th>
                  <td>5 { localeStrings['questions']}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className='pre-line'>{ secondText }</p>
          <div className="flex-row-align-start">
            <div><Lottie className="lottie" animationData={animationJson} loop={true} /></div>
            <div className={styles['button-container']}>
              <button
                  className="onop-button onop-button-primary"
                  aria-label={ localeStrings['continue'] }
                  onClick={() => startSurvey(currentLanguage)}
                >{ localeStrings['continue']}
              </button>
            </div>
          </div>
        </div>
        </>
    );
}

export function Home() {
  return (
      <Layout>
        <HomeContent />
      </Layout>
    );
}
