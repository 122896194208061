import React, { useState, useEffect, useCallback } from 'react';

import { getQuestionText } from '../utils';
import { QuestionChildProps } from './types';
import { Slider } from '../../../components/Slider';
import { NavigationControl } from '../../../components/NavigationControl';

export const TextInput = (props : QuestionChildProps) => {
    const {block, question, clientData, language, response, navigationProps, onChange, onValidated, onFinished} = props;
    const [value, setValue] = useState(response ?? '');
    const [error, setError] = useState<string>('');
    const questionText = getQuestionText(question, language);
    const hasSlider = clientData?.hasSlider ?? false;
    const inputType = clientData?.inputType ?? 'text';
    const secondaryText = clientData?.secondaryText && language ? clientData.secondaryText[language] : '';
    const validationSettings = question.validationSettings;
    const minValue = validationSettings?.minimum;
    let maxValue = validationSettings?.maximum;
    
    function finish(): void {
        if (validate(true)) {
            if (onFinished) {
                onFinished(value);
            }
        }
    }

    const handlePrevious = () => {
        if (validate(true)) {
            if (navigationProps?.onPrevious) {
                navigationProps.onPrevious();
            }
        }
    }

    const handleNext = () => {
        if (validate(true)) {
            if (navigationProps?.onNext) {
                navigationProps.onNext();
            }
        }
    }

    const validate = useCallback((final: boolean) => {
        if (value) {
            if (minValue !== undefined) {
                if (Number(value) < minValue) {
                    if (final) {
                        // only warn about minimum when user is finished
                        setError(`Please enter a number between ${minValue} and ${maxValue}`);
                    }
                    if (onValidated) {
                        onValidated(false);
                    }
                    return false;
                }
            }
            if (maxValue !== undefined && maxValue > (minValue ?? 0)) {
                if (Number(value) > maxValue) {
                    setError(`Please enter a number between ${minValue} and ${maxValue}`);
                    if (onValidated) {
                        onValidated(false);
                    }
                    return false;
                }
            }
        }
        // passed validation
        setError('');
        if (onValidated) {
            onValidated(true);
        }
        return true;
    }, [value, minValue, maxValue, onValidated]);

    useEffect(() => {
        validate(false)
    }, [value, validate]);
    
    return (
        <div className="question-container flex-column-center">
            <div className="question">{ questionText }</div>
            <input
                className="text-input text-input-3"
                autoFocus
                type={inputType}
                aria-label={questionText}
                value={value}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        finish();
                    }
                }}
                onChange={(e) => {
                    setValue(e.target.value);
                    if (onChange) {
                        onChange(e.target.value);
                    }
                }}
            />
            { secondaryText && !value && <div className="secondary-text pre-line">{secondaryText}</div> }
            { error && <div className="error">{error}</div> }
            { hasSlider && !!value && <div className="slider-container"><Slider minValue={minValue ?? 0} maxValue={maxValue ?? 0} 
                value={ value ? Number(value) : undefined } /></div> }
            <NavigationControl sectionClass={`${block?.name ?? 'unknown'}`} 
                enabled={navigationProps?.enabled ?? true}
                showPrevious={navigationProps?.showPrevious ?? true} 
                showNext={navigationProps?.showNext ?? true}
                onPrevious={handlePrevious}
                onNext={handleNext}
                    />
        </div>);
}
