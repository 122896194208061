import React from 'react';
import Lottie from "lottie-react";

import { ONENATIONONEPROJECT_URL } from '../constants';
import { Layout } from '../layouts/Layout';
import {
  selectHasPhoneLayout,
  selectLocaleStrings
} from '../features/survey/surveySlice';

import { useAppSelector } from '../app/hooks';
import styles from './Closed.module.scss';
import afebLogo from '../images/AFEBlogo.png';
import zipJson from '../animations/S0-ZC.json';
import desktopJson from '../animations/desktop/S0-ZC.json';
import onopLogo from '../images/ONOP-logo.png';

export function ClosedContent() {
  const localeStrings = useAppSelector(selectLocaleStrings);
  const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
  const animationJson = hasPhoneLayout ? zipJson : desktopJson;

  return (
      <>
        { hasPhoneLayout ? 
          <>
            <div className="content-panel background-container">
                <div className='lottie-container'>
                    <Lottie className="lottie" animationData={animationJson} loop={true} />
                </div>
            </div>
            <div className="content-relative content-panel padded-panel">
                <h2 className="centered">
                    <img className={styles.afebLogo} src={afebLogo} alt="Arts for Everybody" />
                </h2>
                <div className={`question-container message-text ${styles.firstMessage}`}>{ localeStrings['surveyComplete'] }</div>
                <div className={`question-container message-text ${styles.secondMessage}`} dangerouslySetInnerHTML={{__html: localeStrings['surveyComplete2'] }}></div>
                <div className="centered">
                    <a href="/ap" className={`onop-button anchor-button ${styles.continueButton}`}>
                        { localeStrings['continue'] }</a>
                </div>
                <div className={styles.linkContainer}>
                    <div><a href={ONENATIONONEPROJECT_URL} className={`onop-button wide-button ${styles.linkButton}`}>Visit the website</a></div>
                </div>
            </div>
          </> :
           <>
            <div className="content-panel background-container">
                <div className='lottie-container'>
                    <Lottie className="lottie" animationData={animationJson} loop={true} />
                </div>
                <h2 className={styles.afebLogoContainer}>
                    <img className={styles.afebLogo} src={afebLogo} alt="Arts for Everybody" />
                </h2>
                <div className={styles.version}>v{process.env.REACT_APP_VERSION}</div>
            </div>
            <div className="content-relative content-panel padded-panel">
                <h2 className="centered">
                    <img className={styles.onopLogo} src={onopLogo} alt="One Nation/One Project: A National Arts & Wellness Initiative" />
                </h2>
                <div className={`question-container message-text ${styles.firstMessage}`}>{ localeStrings['surveyComplete'] }</div>
                <a href="/ap"><div className={`question-container message-text ${styles.secondMessage}`} dangerouslySetInnerHTML={{__html: localeStrings['surveyCompleteAp'] }}></div></a>
                <div className={styles.linkContainer}>
                    <div><a href={ONENATIONONEPROJECT_URL} className={`onop-button wide-button ${styles.linkButton}`}>Visit the website</a></div>
                </div>
            </div>
          </>
        }
        </>
    );
}

export function Closed() {
  return (
      <Layout layoutClass="layout-intro">
        <ClosedContent />
      </Layout>
    );
}
