
export interface QuestionTextProps {
    contentType: string;
    questionText: string;
  };

export const QuestionText = (props : QuestionTextProps) => {
    const { contentType, questionText } = props;
    
    return (
        <>
            { contentType === 'html' && 
                <div className="static-text" dangerouslySetInnerHTML={{__html: questionText }}></div> }
            { contentType !== 'html' && 
                <div className="static-text">{ questionText }</div> }
        </>
    );
  }


