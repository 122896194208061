import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './Giveback.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from "lottie-react";

import {
    selectClientData,
    selectCurrentResponseSet,
    selectLocaleStrings,
    loadAnimationAsync,
    selectAnimationStatus,
    selectHasPhoneLayout,
    selectLayoutType,
  } from '../features/survey/surveySlice';
import { animationCache, getAnimationPath } from '../features/survey/utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RightArrow } from './RightArrow';
import { AppHeader } from './AppHeader';

import { ONOP_ARTS_HEALTH_URL, SMALL_DESKTOP_BREAKPOINT } from '../constants';
import { Layout } from '../layouts/Layout';

export function Giveback() {
    const { surveyid, page } = useParams();
    const awardRef = useRef<HTMLDivElement | null>(null);
    const step = Number(page ?? 0);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const responseSet = useAppSelector(selectCurrentResponseSet);
    const surveyClientData = useAppSelector(selectClientData);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const layoutType = useAppSelector(selectLayoutType);
    const questionId = surveyClientData.participationYearId;
    const days = questionId ? (Number(responseSet.responses[questionId] ?? '0')) : 0;
    const daysPerMonth = Math.floor(days * 10 / 12) / 10;
    let awardTextStyle = (step === 0 ? styles.awardMiddle : styles.awardHigh);
    if (layoutType === 'tablet') {
        awardTextStyle = styles.awardTablet;
    } 
    else if (layoutType === 'desktop') {
        awardTextStyle = styles.awardDesktop;
    }

    // in this view, we need to detect small desktop heights
    const smallDesktopMq = window.matchMedia(`(max-height: ${SMALL_DESKTOP_BREAKPOINT-1}px)`);

    const [animation, setAnimation] = useState<any|undefined>(undefined);
    const animationStatus = useAppSelector(selectAnimationStatus);
    const animationFiles = useMemo(() => ['data-giveback-0.json', 'data-end_1-B.json', 'data-end_2-B.json', 'data-end_3-B.json', 'data-end_badge-top.json'], []);
    const animationFile = getAnimationPath(animationFiles[step], hasPhoneLayout);
    
    const textKeys = ['giveback1', 'giveback2', 'giveback3', 'giveback4', 'giveback4'];
    const didYouKnow = step > 0 && (hasPhoneLayout || step < animationFiles.length - 1);
    const lastStep = step === animationFiles.length - 1;

    let message = localeStrings[textKeys[step]];
    let award = 'greatStart';
    if (daysPerMonth >= 4) {
        award = 'awesome';
    }
    else if (daysPerMonth > 1) {
        award = 'fantastic';
    }
    const awardString = hasPhoneLayout || !lastStep ? localeStrings[award] : '';

    if (step === 0) {
        message = message.replace('%value%', `${daysPerMonth}`);
    }

    if (awardRef.current) {
        if (hasPhoneLayout) {
            const scaleY = awardRef.current.clientWidth / 384;
            awardRef.current.style.top = `${scaleY * (step === 0 ? 280 : 90)}px`;
        }
        else if (layoutType === 'desktop') {
            const scaleY = awardRef.current.clientWidth / 540;
            awardRef.current.style.top = `${scaleY * 190}px`;
            awardRef.current.style.left = '52px';

            if (smallDesktopMq.matches) {
                awardRef.current.style.top = `${scaleY * 158}px`;
            }
        }
        else if (layoutType === 'tablet') {
            const scaleY = awardRef.current.clientWidth / 450;
            awardRef.current.style.top = `${scaleY * 120}px`;
            awardRef.current.style.left = '0';
        }
    }
    
    useEffect(() => {
        // load animation
        if (animationFile) {
          const loadStatus = animationStatus[animationFile];
          if (loadStatus === 'loaded') {
            const newAnimation = animationCache.get(animationFile);
            if (animation !== newAnimation) {
              setAnimation(animationCache.get(animationFile));
            }
          }
          else if (!loadStatus) {
            // load the animation
            dispatch(loadAnimationAsync(animationFile));
            setAnimation(undefined);
          }
        }
        else {
          setAnimation(undefined);
        }
    }, [animation, animationFile, animationStatus, dispatch]);
  
    useEffect(() => {
      // preload animations
      for (const filename of animationFiles) {
        const animationPath = getAnimationPath(filename, hasPhoneLayout);
        if (!animationStatus[animationPath!]) {
            dispatch(loadAnimationAsync(animationPath!));
        }
      }
    }, [dispatch, animationStatus, animationFiles, hasPhoneLayout]);

    function handleNext() {
        if (step < animationFiles.length - 1) {
            navigate(`/data/${surveyid}/${step + 1}`);
        }
    }

    return (
        <Layout layoutClass="layout-giveback">
            <div className='background-container padded-panel'><div className='lottie-container'>
                <Lottie className="lottie" animationData={animation} loop={true} />
                </div>
                { !hasPhoneLayout && <div ref={awardRef} className={`${styles.awardText} ${awardTextStyle}`}>{ awardString }</div> }
            </div>
            <div className={`z-front section-giveback content-panel padded-panel`}>
                { !hasPhoneLayout && <AppHeader surveyType={surveyClientData.surveyType} /> }
                { !hasPhoneLayout && step === 0 && <div className={`main-title ${styles.weCalculated}`}>{ localeStrings['weCalculated']}</div> }
                { didYouKnow && <div className={styles.didYouKnow}>
                    <div className='main-title centered'>{ localeStrings['didYouKnow'] }</div>
                    </div> }
                <div className={`question-container ${step > 0 || !hasPhoneLayout ? styles.contentMiddle : ''}`}>
                    <div className="question-large">{ message }</div>
                    { step < animationFiles.length - 1 && <div className='right-aligned clickable'
                        onClick={() => handleNext()} >
                            <RightArrow />
                        </div> }
                </div>
                { lastStep && hasPhoneLayout &&
                    <div className={styles.finalContainer}>
                        <div className='centered'>
                            <a href={ONOP_ARTS_HEALTH_URL} className="onop-button anchor-button">
                            { localeStrings['givebackButton'] }</a>
                        </div>
                        <button className='secondary-button'
                            aria-label={ localeStrings['credits'] }
                            onClick={() => navigate("/credits")}>
                            { localeStrings['credits'].toLowerCase() }
                        </button>
                    </div> }
                { lastStep && !hasPhoneLayout &&
                    <>
                        <div className={`centered ${styles.givebackButton}`}>
                            <a href={ONOP_ARTS_HEALTH_URL} className="onop-button anchor-button">
                            { localeStrings['givebackButton'] }</a>
                        </div>
                        <button className={`secondary-button ${styles.creditsButton}`}
                            aria-label={ localeStrings['credits'] }
                            onClick={() => navigate("/credits")}>
                            { localeStrings['credits'].toLowerCase() }
                        </button>
                    </>
                }
            </div>
            { hasPhoneLayout && <div ref={awardRef} className={`${styles.awardText} ${awardTextStyle}`}>{ awardString }</div> }
        </Layout>
    );
  }