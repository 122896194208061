import React, { useState, useEffect, useMemo } from 'react';
import styles from './Credits.module.scss';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";

import {
    selectLocaleStrings,
    selectHasPhoneLayout,
    loadAnimationAsync,
    selectAnimationStatus,
  } from '../features/survey/surveySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { animationCache } from '../features/survey/utils';

import { Layout } from '../layouts/Layout';
import {LeftArrow } from './LeftArrow';

export function Credits() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const [animation, setAnimation] = useState<any|undefined>(undefined);
    const animationStatus = useAppSelector(selectAnimationStatus);
    const animationFile = useMemo(() => 'desktop/Z-congrats.json', []);
    
    useEffect(() => {
      // load animation
      if (animationFile) {
        const loadStatus = animationStatus[animationFile];
        if (loadStatus === 'loaded') {
          const newAnimation = animationCache.get(animationFile);
          if (animation !== newAnimation) {
            setAnimation(animationCache.get(animationFile));
          }
        }
        else if (!loadStatus) {
          // load the animation
          dispatch(loadAnimationAsync(animationFile));
          setAnimation(undefined);
        }
      }
      else {
        setAnimation(undefined);
      }
    }, [animation, animationFile, animationStatus, dispatch]);
    
    const handleBack = () => {
        navigate(-1);
      }

    return (
        <Layout layoutClass="layout-giveback">
            { !hasPhoneLayout && <div className="background-container">
              <div className="lottie-container">
                <Lottie className="lottie" animationData={animation} loop={true} />
              </div>
            </div> }
            <div className='content-relative'>
              <div className={styles.creditsContainer}>
                  <div className={styles.sectionContainer}>
                      <div className={styles.title}>App</div>
                      <div className={styles.description}>designed and engineered by</div>
                      <div className={styles.name}>Tactile Pictures</div>
                      <div className={styles.name}>Nisma Zaman, Director</div>
                      <div className={styles.name}>Derek Chung</div>
                      <div className={styles.description}>illustration and animation by</div>
                      <div className={styles.name}>Sophia Rose Cruz</div>
                  </div>
                  <div className={styles.sectionContainer}>
                      <div className={styles.title}>Survey</div>
                      <div className={styles.description}>Director of National Research & Impact</div>
                      <div className={styles.name}>Dr. Jill Sonke, Ph.D.</div>
                      <div className={styles.description}>Research Manager</div>
                      <div className={styles.name}>Nicole Morgan</div>
                  </div>
                  <div className={styles.sectionContainer}>
                      <div className={styles.title}>Supported by</div>
                      <div className={styles.name}>One Nation / One Project</div>
                      <div>in partnership with University of Florida Center for Arts in Medicine and the Office of the Vice President for the Arts at Stanford University. ONOP is fiscally sponsored by Tides Center, a 501(c)(3) non-profit organization.</div>
                  </div>
              </div>
              <div className={styles.arrowContainer}><button
                      className='nav-button'
                      aria-label={ localeStrings['back'] }
                      onClick={handleBack}
                    ><LeftArrow />
                  </button></div>
            </div>
        </Layout>
    );
  }