import React, { useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Lottie from "lottie-react";

import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Layout } from '../layouts/Layout';
import { AppHeader } from './AppHeader';
import { DEFAULT_SURVEY_ID } from '../constants';
import { getClientDataForSurvey } from '../features/survey/clientData';
import {  SurveyClientData, SurveyOptions } from '../features/survey/types';
import consentJson from "../animations/consent-div.json";
import { ScrollArrow } from "./ScrollArrow";

import {
    loadSurveyAsync,
    selectCurrentLanguage,
    selectLocaleStrings,
    selectHasPhoneLayout
  } from '../features/survey/surveySlice';
import styles from './Consent.module.scss';

const consentText: { [key: string]: string[] }= {
  'en': [ 
    'This survey is part of a research study being conducted by the University of Florida (UF) Center for Arts in Medicine and One Nation/One Project, a national arts and wellness initiative. More information about One Nation/One Project is available at <a href="https://onenationoneproject.com" target="_blank">onenationoneproject.com</a>.',
    'We are inviting members of your community to complete a survey to help us learn about social cohesion - the strength of relationships and the sense of solidarity among members of a community.  We will also ask you questions about your participation in the arts, your wellbeing, and your perceptions about how these things are related.',
    'The following 24 questions will take approximately 7-10 minutes to complete. You must be 18 years or older to participate in this study.',
    'Your participation in this research is confidential. Information you share will be stored in computers with security passwords. Only the study team will have access to these records.',
    'There are no risks to participating in the study. You have the right to stop the survey at any point.',
    'If you have any questions, please contact our Principal Investigator, Dr. Jill Sonke, at jsonke@ufl.edu. You may also contact the UF IRB at (352) 392-0433.',
    'By selecting yes, you are confirming that you are 18 years old or older and consent to participate in this survey.',
    ],
  'es': [ 
    'Esta encuesta es parte de un estudio de investigación realizado por el Centro de Artes en Medicina de la Universidad de Florida (UF) y One Nation/One Project, una iniciativa nacional de arte y bienestar. Más información sobre One Nation/One Project está disponible en <a href="https://onenationoneproject.com" target="_blank">onenationoneproject.com</a>.',
    'Estamos invitando a miembros de su comunidad a completar una encuesta para ayudarnos a aprender sobre la cohesión social: la fortaleza de las relaciones y el sentido de solidaridad entre los miembros de una comunidad. También le haremos preguntas sobre su participación en actividades artísticas, su bienestar y sus percepciones sobre cómo se relacionan estas cosas.',
    'Las siguientes 24 preguntas tardarán aproximadamente entre 7 y 10 minutos en completarse. Debe tener 18 años o más para participar en este estudio.',
    'Su participación en esta investigación es confidencial. La información que comparta se almacenará en computadoras con contraseñas seguras. Sólo el equipo de investigación tendrá acceso a estos registros.',
    'No existen riesgos al participar en el estudio. Tiene derecho a detener la encuesta en cualquier momento.',
    'Si tiene alguna pregunta, comuníquese con nuestra investigadora principal, la Dra. Jill Sonke, en jsonke@ufl.edu. También puede comunicarse con el IRB de UF al (352)-392-0433.',
    'Al seleccionar Sí, confirma que tiene 18 años o más y acepta participar en esta encuesta.',
    ],
  };

const apConsentText: { [key: string]: string[] } = {
  'en': [
    'This survey is part of a research study being conducted by the University of Florida Center for Arts in Medicine and One Nation/One Project, a multi-faceted national art in public health initiative designed to activate the power of arts and culture to strengthen the social fabric of our nation. More information on One Nation/One Project is available at  <a href="https://onenationoneproject.com" target="_blank">onenationoneproject.com</a>.',
    'We are inviting members of your community to complete a survey intended to assess social cohesion - the strength of relationships and the sense of solidarity among members of a community. We will also ask you questions about your participation in the arts, your wellbeing, and your perceptions about how these things are related.',
    'The following 10 questions will take about 1-2 minutes to complete. You must be 18 years or older to participate in this study.',
    'Your participation in this research is confidential. Information collected about you will be stored in computers with security passwords. Only the study team will have the right to review these research records, and they will protect the secrecy (confidentiality) of these records.',
    'There are no risks to participating in the study. You have the right to stop the survey at any point.',
    'If you have any questions, please contact our Principal Investigator, Dr. Jill Sonke, at jsonke@ufl.edu. You may also contact the UF IRB at (352) 392-0433.',
    'By selecting yes, you are confirming that you are 18 years old or older and consent to participate in this survey.',
  ],
  'es': [
    'Esta encuesta es parte de un estudio de investigación realizado por el Centro de Artes en Medicina de la Universidad de Florida y One Nation/One Project, una iniciativa nacional multifacética de arte en salud pública diseñada para activar el poder de las artes y la cultura para fortalecer la sociedad. tejido de nuestra nación. Más información sobre One Nation/One Project está disponible en <a href="https://onenationoneproject.com" target="_blank">onenationoneproject.com</a>.',
    'Estamos invitando a miembros de su comunidad a completar una encuesta destinada a evaluar la cohesión social: la fortaleza de las relaciones y el sentido de solidaridad entre los miembros de una comunidad. También le haremos preguntas sobre su participación en las artes, su bienestar y sus percepciones sobre cómo se relacionan estas cosas.',
    'Las siguientes 10 preguntas tardarán entre 1 y 2 minutos en completarse. Debe tener 18 años o más para participar en este estudio.',
    'Su participación en esta investigación es confidencial. La información recopilada sobre usted se almacenará en computadoras con contraseñas de seguridad. Sólo el equipo del estudio tendrá derecho a revisar estos registros de investigación y protegerá el secreto (confidencialidad) de estos registros.',
    'No existen riesgos al participar en el estudio. Tiene derecho a detener la encuesta en cualquier momento.',
    'Si tiene alguna pregunta, comuníquese con nuestra investigadora principal, la Dra. Jill Sonke, en jsonke@ufl.edu. También puede comunicarse con el IRB de UF al (352)-392-0433.',
    'Al seleccionar Sí, confirma que tiene 18 años o más y acepta participar en esta encuesta.',
  ]
};


function ConsentText(props : { clientData: SurveyClientData, handleScroll: (e: Event) => void; }) {
  const { clientData, handleScroll } = props;
  const localeStrings = useAppSelector(selectLocaleStrings);
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const consentTextMap = clientData.surveyType === 'ap' ? apConsentText : consentText;
  const consentContent = consentTextMap[currentLanguage] ?? consentTextMap['en'];
  const scrollRef = useRef<HTMLDivElement | null>(null);
    
  useEffect(() => {
    const div = scrollRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }
    // cleanup if we unmount
    return () => {
      div?.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (<>
      <div className={styles.sectionHeading}>
        <div className={styles.subtitle}>{ localeStrings[`${clientData.surveyType}StudyNumber`] }</div>
        <div className={styles.subtitle}>{ localeStrings['consentTitle'] }</div>
      </div>
      <div className="content-relative"><ScrollArrow /></div>
      <div className={styles.scrollContainer} ref={scrollRef}>
        <div className={styles.surveyInfo}>
          { consentContent.map((paragraph, i) => <p key={`p${i}`} dangerouslySetInnerHTML={{__html: paragraph }}></p>)}
        </div>  
      </div>
      </>);
}

export function Consent() {
    let { surveyid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentLanguage = useAppSelector(selectCurrentLanguage);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);

    const localeStrings = useAppSelector(selectLocaleStrings);
    const [, setCookie] = useCookies(['sessionId']);
    const [starting, setStarting] = useState<boolean>(false);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const clientData = getClientDataForSurvey(surveyid ?? DEFAULT_SURVEY_ID);
    
    const handleScroll = useCallback((e: Event) => {
      const target: HTMLDivElement = e.target as HTMLDivElement;
      if (target) {
        const bottom = target.scrollTop >= (target.scrollHeight - target.clientHeight - 20);
        if (bottom) {
          setEnabled(true);
          setMessage('');
        }
      }
    }, []);
       
    useEffect(() => {
      if (surveyid) {
          // preload survey while user is here
          const options: SurveyOptions = {
              surveyId: surveyid,
              language: currentLanguage,
            }
          dispatch(loadSurveyAsync(options));
      }
    }, [dispatch, surveyid, currentLanguage]);

    useEffect(() => {
      if (!hasPhoneLayout) {
        setMessage(localeStrings['scrollToEndDesktop']);
      }
    }, [hasPhoneLayout, localeStrings]);

    function startSurvey(): void {
      if (enabled) {
        // clear cookie when starting from here
        setCookie('sessionId', '');
        if (!starting) {
          setStarting(true);
          navigate(`/survey/${surveyid ?? DEFAULT_SURVEY_ID}/${currentLanguage}/${clientData.startQuestionId ?? ''}`);
        }
      }
      else {
        setMessage(localeStrings['scrollToEnd']);
      }
    }

    return (
        <Layout layoutClass="layout-intro">
          <div className={`${styles['consent-container']} ${clientData.surveyType}-survey section-intro framed-container left-panel`}>
            <AppHeader surveyType={clientData.surveyType} />
            <div className={styles.questionContainer}>
              <div className={styles.prompt}>{ localeStrings['consentPrompt'] }</div>
              <div className={ hasPhoneLayout ? 'flex-row-align-center' : 'flex-column-center flex-no-gap'}>
                <div className={styles.lottieContainer}>
                  <Lottie className="lottie" animationData={consentJson} loop={true} />
                </div>
                <div className="action-buttons">
                  <div>
                    <div
                        className={`onop-button ${ enabled ? styles.enabled : styles.disabled }`}
                        aria-label="Start Survey (English)"
                        onClick={() => startSurvey()}
                      >{ localeStrings['yes'].toLocaleUpperCase() }</div>
                  </div><div>
                    <div
                        className={`onop-button ${ enabled ? styles.enabled : styles.disabled  }`}
                        onClick={() => navigate(`/exit/${clientData.surveyType}`)}
                      >{ localeStrings['no'].toLocaleUpperCase() }</div>
                  </div> 
                </div>
              </div>
            </div>
            { message && <div className={styles.infoMessage}>{ message } </div>}
            { hasPhoneLayout && <>
              <ConsentText clientData={clientData} handleScroll={handleScroll} />
              <div className={styles.smallPhoneButtons}>
                  <div
                        className={`onop-button ${ enabled ? styles.enabled : styles.disabled }`}
                        aria-label="Start Survey (English)"
                        onClick={() => startSurvey()}
                      >{ localeStrings['yes'].toLocaleUpperCase() }</div>
                  <div
                        className={`onop-button ${ enabled ? styles.enabled : styles.disabled  }`}
                        onClick={() => navigate(`/exit/${clientData.surveyType}`)}
                      >{ localeStrings['no'].toLocaleUpperCase() }</div>
                  </div>
              <div className={styles.marginBottom}></div>
            </> }
          </div>
          { !hasPhoneLayout && <div className={`${styles['consent-container']} ${clientData.surveyType}-survey section-intro padded-panel right-panel`}>
              <ConsentText clientData={clientData} handleScroll={handleScroll} />
          </div> }
        </Layout>
      );
}