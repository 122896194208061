import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

import { useAppSelector, useAppDispatch } from '../../app/hooks';

import {
  startSurveyAsync,
  selectClientData,
  selectCurrentLanguage,
  selectCurrentBlock,
  selectCurrentQuestion,
  selectCurrentResponse,
  selectCurrentResponseSet,
  selectCurrentSessionId,
  selectCurrentSurveyId,
  selectSurveyStatus,
  setNewQuestionId,
  selectNewQuestionId,
  setCurrentQuestionId,
  selectQuestionClientData,
  selectErrorMessage,
  selectLocaleStrings,
} from './surveySlice';
import {  SurveyOptions } from './types';

import { QuestionView } from './questions/QuestionView';
import styles from './Survey.module.scss';
import { Layout } from '../../layouts/Layout';
import { Loading } from '../../components/Loading';

export function Survey() {
  const { surveyid, language, questionid } = useParams();

  const block = useAppSelector(selectCurrentBlock);
  const question = useAppSelector(selectCurrentQuestion);
  const response = useAppSelector(selectCurrentResponse);
  const status = useAppSelector(selectSurveyStatus);
  const currentSurveyId = useAppSelector(selectCurrentSurveyId);
  const currentSessionId = useAppSelector(selectCurrentSessionId);
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const newQuestionId = useAppSelector(selectNewQuestionId);
  const responseSet = useAppSelector(selectCurrentResponseSet);
  const clientData = useAppSelector(selectClientData);
  const questionClientData = useAppSelector(selectQuestionClientData);
  const errorMessage = useAppSelector(selectErrorMessage);
  const localeStrings = useAppSelector(selectLocaleStrings);
  const sectionName = question?.clientData?.section ?? block?.name ?? 'unknown';
  const questionLayout = question?.questionType === 'DB' ? 'layout-overview' : 'layout-question';

  const [cookies, setCookie] = useCookies(['sessionId', 'onop_access']);
  const sessionCookie = cookies.sessionId;
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (surveyid && (!currentSessionId || (surveyid !== currentSurveyId))) {
      const sessionId = sessionCookie;
      const options: SurveyOptions = {
        surveyId: surveyid,
        language: language || currentLanguage,
        sessionId,
        questionId: questionid,
      }
      
      dispatch(startSurveyAsync(options));
    }
    else if (questionid && questionid !== question?.questionId) {
      dispatch(setCurrentQuestionId(questionid));
    }
  }, [dispatch, surveyid, language, currentLanguage, currentSessionId, currentSurveyId, question, questionid, sessionCookie]);

  useEffect(() => {
    if (currentSessionId) {
      setCookie('sessionId', currentSessionId);
    }
  }, [setCookie, currentSessionId]);

  useEffect(() => {
    if (status === 'failed') {
      ReactGA.event({
        category: clientData.surveyType,
        action: "failed to load survey",
      });
    }
  }, [status, clientData]);

  // go to new question if newQuestionId in state
  useEffect(() => {
    if (newQuestionId === '_thankyou') {
      // report completion
      ReactGA.event({
        category: clientData.surveyType,
        action: "completed",
      });

      // go to the thank you and credits first
      navigate(`/submitted/${currentSurveyId}`);
    }
    else if (newQuestionId === '_quota') {
      // report quota limit
      ReactGA.event({
        category: clientData.surveyType,
        action: "quota",
      });

      // go to the ineligible screen
      navigate(`/quota/${clientData.surveyType}`);
    }
    else if (newQuestionId === '_error') {
      // report error
      ReactGA.event({
        category: clientData.surveyType,
        action: "error",
        label: errorMessage, // optional
      });

      // go to the error page
      navigate(`/error/${currentSurveyId}`);
    }
    else if (newQuestionId && newQuestionId !== questionid) {
      navigate(`/survey/${currentSurveyId}/${currentLanguage}/${newQuestionId}`);
    }
    if (newQuestionId) {
      dispatch(setNewQuestionId(''));
    }
  }, [dispatch, navigate, clientData, currentSurveyId, currentLanguage, errorMessage, questionid, newQuestionId]);

  return (
    <Layout layoutClass={`${styles.questionLayout} layout-${sectionName} layout-${clientData.surveyType} ${questionLayout}`}>
      { status === 'failed' && <div className={styles.error}>{ localeStrings[errorMessage ?? 'surveyNotFound'] }</div> }
      { status === 'loading' && <Loading />}
      { status === 'loaded' && <div className={`${clientData.surveyType}-survey ${styles.surveyContainer}`}>
        { question && <QuestionView block={block} question={question} 
          language={language} clientData={questionClientData}
          response={response ?? ''} responseSet={responseSet} /> }
      </div> }
    </Layout>
  );
}
