import React, { useState, useEffect, useMemo } from 'react';
import Lottie from "lottie-react";
import { useNavigate, useParams } from 'react-router-dom';

import styles from './ThankYou.module.scss';
import buttonJson from "../animations/buttonSpikes.json";

import {
    selectLocaleStrings,
    selectClientData,
    loadAnimationAsync,
    selectAnimationStatus,
    selectHasPhoneLayout,
  } from '../features/survey/surveySlice';
import { animationCache } from '../features/survey/utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import { Layout } from '../layouts/Layout';
import { RightArrow } from './RightArrow';
import { AppHeader } from './AppHeader';

export function ThankYou() {
    const { surveyid } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const surveyClientData = useAppSelector(selectClientData);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const [animation, setAnimation] = useState<any|undefined>(undefined);
    const animationStatus = useAppSelector(selectAnimationStatus);
    const animationFile = useMemo(() => (hasPhoneLayout ? 'Z-congrats.json' : 'desktop/Z-congrats.json'), [hasPhoneLayout]);
    
    useEffect(() => {
      // load animation
      if (animationFile) {
        const loadStatus = animationStatus[animationFile];
        if (loadStatus === 'loaded') {
          const newAnimation = animationCache.get(animationFile);
          if (animation !== newAnimation) {
            setAnimation(animationCache.get(animationFile));
          }
        }
        else if (!loadStatus) {
          // load the animation
          dispatch(loadAnimationAsync(animationFile));
          setAnimation(undefined);
        }
      }
      else {
        setAnimation(undefined);
      }
    }, [animation, animationFile, animationStatus, dispatch]);

    const handleNext = () => {
      const path = surveyClientData?.givebackPath;
      if (path) {
        navigate(`${path}/${surveyid}`);
      } else {
        navigate('/credits');
      }
    }

    return (
        <Layout layoutClass="layout-giveback">
            <div className="background-container">
              <div className="lottie-container">
                <Lottie className="lottie" animationData={animation} loop={true} />
              </div>
            </div>
            <div className={`z-front padded-panel ${surveyClientData.surveyType}-survey ${styles.top}`}>
              { !hasPhoneLayout && <AppHeader surveyType={surveyClientData?.surveyType}></AppHeader> }
              <div className={styles.thankyou}>{ localeStrings['thankyouComplete'] }</div>
              <div className={styles.buttonContainer}>
                { !hasPhoneLayout && <div className={styles.buttonAnimationContainer}>
                  <Lottie className="lottie" animationData={buttonJson} loop={true} />
                </div> }
                <button
                    className={`onop-button flex-row-align-center ${styles.moreButton}`}
                    aria-label={ localeStrings['waitMore'] }
                    onClick={handleNext}
                  >{ localeStrings['waitMore'] } <RightArrow />
                </button>
              </div>
            </div>
        </Layout>
    );
  }