import React, { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';

import {
  selectUnsubmittedResponses,
  submitOfflineResponsesAsync,
  loadOfflineResponsesAsync,
} from './surveySlice';
import styles from './Survey.module.scss';
import { Layout } from '../../layouts/Layout';

export function SurveyAdmin() {
  const offlineResponses = useAppSelector(selectUnsubmittedResponses);

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(loadOfflineResponsesAsync(''));
  }, [dispatch]);

  return (
    <Layout>
        { offlineResponses && offlineResponses.map(response => 
            <div className={styles.unsubmitted} key={response.sessionId}>{ response.sessionId }</div>)}
        <button
            className={styles.button}
            aria-label="Submit offline responses"
            onClick={() => dispatch(submitOfflineResponsesAsync(offlineResponses))}
            >Submit offline responses</button>
    </Layout>
  );
}
