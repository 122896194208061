import React, { useRef, useState }  from "react";
import Lottie from "lottie-react";
import { useAppSelector } from '../../../app/hooks';

import {
    selectHasPhoneLayout,
    selectHasDesktopLayout,
    selectLocaleStrings,
  } from '../surveySlice';

import { AppHeader } from '../../../components/AppHeader';
import { ContentType } from '../types';
import { NavigationControl } from '../../../components/NavigationControl';

import styles from './ZipCode.module.scss';
import zipJson from '../../../animations/S0-ZC.json';
import desktopJson from '../../../animations/desktop/S0-ZC.json';

export interface ZipCodeProps {
    questionText: string;
    validatedText?: string;
    contentType?: ContentType;
    validated: boolean;
    buttonLabel?: string;
    alwaysAnimateBg?: boolean;
    onValidate: (value: string, choice?: string) => void;
    onNext: () => void;
  };

export const ZipCode = (props : ZipCodeProps) => {
    const {questionText, validatedText, contentType, buttonLabel, alwaysAnimateBg, validated, onValidate, onNext} = props;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState('');
    const localeStrings = useAppSelector(selectLocaleStrings);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const hasDesktopLayout = useAppSelector(selectHasDesktopLayout);
    const animateBg = alwaysAnimateBg || validated || !hasPhoneLayout;
    const animationJson = hasPhoneLayout ? zipJson : desktopJson;
    const secondaryText = localeStrings['useYourKeypad']
        .replace("keyboard to", "keyboard\nto")
        .replace("teclado para", "teclado\npara");
        
    function validate(zip: string) {
        onValidate(zip);
    }

    const digits: string[] = [];
    for (let z = value; z.length > 0; z = z.substring(1)) {
        digits.push(z.charAt(0));
    }
    while (digits.length < 5) {
        digits.push('');
    }

    const navigationControl = <NavigationControl  
      enabled={validated}
      showPrevious={ false } 
      showNext={true}
      onNext={onNext}
      />;
    
    return (
         <>
            <div className={ `content-panel background-container ${ animateBg ? '' : styles.bg}` }
                onClick={() => {inputRef.current?.focus()}}>
                { animateBg && <div className='lottie-container'>
                        <Lottie className="lottie" animationData={animationJson} loop={true} />
                    </div> }
            </div>
            <div className="content-relative content-panel padded-panel"
                onClick={() => {inputRef.current?.focus()}}>
                <AppHeader></AppHeader>
                { hasDesktopLayout && validated && !buttonLabel && navigationControl }
                <div className="question-container">
                    <div className="section-intro">
                        <div className={styles.questions}>
                            { contentType === 'html' && 
                                <div className="question-large" dangerouslySetInnerHTML={{__html: questionText }}></div> }
                            { contentType !== 'html' && 
                                <div className="question-large">{ validated && validatedText ? validatedText : questionText }</div> }
                            <input
                                ref={inputRef} 
                                className={styles.zipInput}
                                disabled={!!validated}
                                autoFocus
                                type="number"
                                autoComplete="off"
                                aria-label={questionText}
                                value={value}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && value.length === 5) {
                                        validate(value);
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length <= 5) {
                                        setValue(e.target.value);

                                        if (e.target.value.length === 5) {
                                            validate(e.target.value);
                                        }
                                    }
                                }}
                            />
                            <div className="flex-row-center">
                                { digits.map((d, i) => <div key={i} className={`text-input ${styles.digit}`}>{d}</div>)}
                            </div>
                            { !hasDesktopLayout && validated && !buttonLabel && navigationControl }
                        </div>
                        { !hasPhoneLayout && !validated && <div className='secondary-text padding-double pre-line'>{ secondaryText }</div> }
                    </div>
                </div>
                { validated && buttonLabel && <>
                    <div className="validated-check zip-check">
                        <svg width="111" height="109" id="checkmark-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.56 109.05"><path fill="currentColor" d="m38.12,107.92c-3.12,1.14-6.46-5.04-8.81-6.88-5.4-5.53-12.53-10.93-19.07-14.54-6.82-3.62-11.74-4.98-7.42-13.72,2.42-4.67,5.02-12.3,7.87-15.53,2.25-1.7,4.91,3.75,6.42,5.36,3.16,4.22,6.8,9.1,10.07,13.46,3.14,4.13,5.28,7.18,7.98,10.57,3.4,4.44,4.2-1.27,5.36-3.77,8.77-23.47,23.24-55.41,42.17-75.08,10.88-12.54,12.27-6.23,20.3,4.38,2.35,3.48,6.82,8.46,7.45,11.95.36,2.39-1.25,3.83-3.94,5.38-18.3,9.37-35.76,27.11-45.73,42.66-4.15,5.99-7.89,11.69-11.56,17.96-2.12,3.61-4.35,7.69-6.57,11.99-1.05,1.96-2.65,4.98-4.41,5.77l-.1.04Z"/></svg>
                    </div>
                    <div className="centered section-intro"><button
                        className="onop-button onop-button-primary"
                        aria-label={ buttonLabel }
                        onClick={onNext}>{ buttonLabel }
                            </button></div>
                    </> }
            </div>
        </>           
    );
}
