import React, { useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { QuestionCounter } from '../../../components/QuestionCounter';
import { SectionCounter } from '../../../components/SectionCounter';

import styles from './QuestionHeader.module.scss';

import {
  selectBlockQuestions,
  selectClientData,
  selectCurrentBlock,
  selectCurrentResponseSet,
  selectLocaleStrings,
} from '../surveySlice';
import { SurveyQuestion } from '../types';

export interface QuestionHeaderProps {
    question?: SurveyQuestion;
    title?: string;
    navEnabled?: boolean;
    showPrevious?: boolean;
    showNext?: boolean;
    sectionClass?: string,
    onPrevious?: () => void;
    onNext?: () => void;
  };

export const QuestionHeader = (props : QuestionHeaderProps) => {
    const { question } = props;
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<number>(0);

    const block = useAppSelector(selectCurrentBlock);
    const responseSet = useAppSelector(selectCurrentResponseSet);
    const surveyClientData = useAppSelector(selectClientData);
    const localeStrings = useAppSelector(selectLocaleStrings);
    const blockCount = surveyClientData.sectionOrder.length;
    const blockIndex = surveyClientData.sectionOrder.indexOf(block.name ?? '');
    const blockQuestions = useAppSelector(selectBlockQuestions);
    const countedQuestions = blockQuestions.filter((question) => {
      if (question?.clientData?.useInCounter === false) {
        return false;
      }
      return (question && question.questionType !== 'DB');
    });
    const countedQuestionIds = countedQuestions.map(question => question!.questionId);
    const questionIndex = question ? (countedQuestionIds.indexOf(question.questionId) ?? 0) : undefined;
    const completedQuestions = countedQuestions.map(question => {
      return !!question && responseSet.responses[question.questionId] !== undefined;
    });
    
    const handleClick = () => {
      if (timerId) {
        window.clearTimeout(timerId);
        setTimerId(0);
      }
      if (!showInfo) {
        // set timer to set it back to false later
        const timerId = window.setTimeout(() => {
          setShowInfo(false);
        }, 4000);
        setTimerId(timerId);
      }
      setShowInfo(!showInfo);
    }
    
    return (
      <header className={styles.header} onClick={handleClick}>
        <div className='section-title'>
             { props.title }
        </div>
        <div className={styles.counterContainer}>
          { (surveyClientData.displaySectionCounter !== false) && <SectionCounter blockName={block.name ?? ''} /> }
          { <QuestionCounter index={questionIndex} count={countedQuestions.length ?? 0} 
              questions={countedQuestions} completed={completedQuestions} /> }
          { showInfo &&
            <div className={`${styles.textInfo} selected z-front`}>
              { (surveyClientData.displaySectionCounter !== false) && <div>Section {blockIndex+1} of {blockCount}</div> }
              { question && question.questionType !== 'DB' && <div>Question { questionIndex! + 1 } of {countedQuestions.length ?? 0}</div> }
              { question && question.questionType === 'DB' && <div>{ localeStrings['thereAreQuestions'].replace('%questions%', `${countedQuestions.length ?? 0}`) }</div> }
            </div> }
        </div>
      </header>
    );
  }
