import React from 'react';
import styles from './QuestionCounter.module.scss';
import { SurveyQuestion } from '../features/survey/types';

interface QuestionCounterProps {
    index?: number;
    count: number;
    questions: (SurveyQuestion|undefined)[];
    completed: boolean[];
}

export function QuestionCounter(props: QuestionCounterProps) {
    const { index, count, questions, completed } = props;

    const circles = [];
    for (let i=0; i < count; ++i) {
        const question = questions[i];
        if (!question || question.questionType === "DB") {
          continue;
        }
        if (completed[i]) {
          // add 'variation selector' character after checkmark for iOS
          // https://stackoverflow.com/questions/32639694/ios-9-removed-the-possibility-to-change-certain-symbol-colors-using-css
          const className = `${ completed[i] ? 'completed' : ''} ${ i === index ? 'current' : ''} ${styles.circle}`;
          circles.push(<div key={i} className={className}><div className={styles.check}>✔&#xfe0e;</div></div>);
        }
        else {
          const className = `${ i === index ? 'current' : 'unanswered'} ${styles.circle}`;
          circles.push(<div key={i} className={className}></div>);
        }
    }

    return (
      <div className={styles.container}>
        { circles }
      </div>
    );
  }