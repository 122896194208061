import styles from './SurveyError.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from "lottie-react";

import {
    selectErrorMessage,
    selectLocaleStrings,
  } from '../features/survey/surveySlice';

import { useAppSelector } from '../app/hooks';
import { Layout } from '../layouts/Layout';
import { NavigationControl } from './NavigationControl';
import { BUG_REPORT_URL } from '../constants';

import errorAnimation from "../animations/error.json";

export function SurveyError() {
  const { surveyid } = useParams();
  const navigate = useNavigate();
  const localeStrings = useAppSelector(selectLocaleStrings);
  const errorMessage = useAppSelector(selectErrorMessage);
  
  const handleNext = () => {
    navigate(`/`);
  }

  return (
    <Layout>
            <div className={`background-container not-phone ${styles.errorAnimationContainer}`}>
              <Lottie className="lottie" animationData={errorAnimation} loop={true} />
            </div>
            <div className={`flex-column-center z-front container content-panel`}>
                <div className={styles.error}>{ localeStrings['errorSorry'] }</div>
                <div className='error'>{ errorMessage }</div>
                <div className={styles.bug}><a href={BUG_REPORT_URL}>{ localeStrings['reportBug'] }</a></div>
                <NavigationControl showNext={true} onNext={handleNext}/>
            </div>
        </Layout>
  );
}
