import React, { useState, useEffect, useMemo } from 'react';
import styles from './APGiveback.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from "lottie-react";

import {
    selectClientData,
    selectCurrentResponseSet,
    selectLocaleStrings,
    loadAnimationAsync,
    selectAnimationStatus,
    selectHasPhoneLayout,
  } from '../features/survey/surveySlice';
import { animationCache, getAnimationPath } from '../features/survey/utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RightArrow } from './RightArrow';
import { AppHeader } from './AppHeader';

import { ONOP_ARTS_HEALTH_URL, ONOP_INTEREST_SURVEY_URL } from '../constants';

import { Layout } from '../layouts/Layout';

export function APGiveback() {
    const {surveyid, page} = useParams();
    const step = Number(page ?? 0);
    const [animation, setAnimation] = useState<any|undefined>(undefined);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const responseSet = useAppSelector(selectCurrentResponseSet);
    const surveyClientData = useAppSelector(selectClientData);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const response1 = responseSet?.responses[surveyClientData.participationQuestion1!] === '1';
    const response2 = responseSet?.responses[surveyClientData.participationQuestion2!] === '1';
    const response3 = responseSet?.responses[surveyClientData.participationQuestion3!] === '1';
    let textKeys: string[] = [];
    let firstBg2Index = 0;

    if (response1 && response2 && response3) {
        textKeys = ['apGiveback1', 'apGiveback2', 'apGiveback3', 'apGiveback4'];
        firstBg2Index = 1;
    }
    if (response3) {
        textKeys.push('apGivebackGlad');
    }
    textKeys.push('apGivebackLearn');

    const animationStatus = useAppSelector(selectAnimationStatus);
    const animationFiles = useMemo(() => ['S1-Q2.json', 'S4-Q1.json'], []);
    const animationFile = getAnimationPath(step < firstBg2Index ? animationFiles[0] : animationFiles[1], hasPhoneLayout);
    const questionStyles: string[] = [];
    const didYouKnow = (step >= 1 && step < textKeys.length - 2);
    if (step >= firstBg2Index) {
        questionStyles.push(`${styles.contentMiddle}`);
    }
    if (step === textKeys.length - 2) {
        questionStyles.push('highlighted');
    }
    if (step === textKeys.length - 1) {
        questionStyles.push('step-final');
    }

    useEffect(() => {
        // load animation
        if (animationFile) {
          const loadStatus = animationStatus[animationFile];
          if (loadStatus === 'loaded') {
            const newAnimation = animationCache.get(animationFile);
            if (animation !== newAnimation) {
              setAnimation(animationCache.get(animationFile));
            }
          }
          else if (!loadStatus) {
            // load the animation
            dispatch(loadAnimationAsync(animationFile));
            setAnimation(undefined);
          }
        }
        else {
          setAnimation(undefined);
        }
    }, [animation, animationFile, animationStatus, dispatch]);
  
    useEffect(() => {
      // preload animations
      for (const filename of animationFiles) {
        if (!animationStatus[filename]) {
            dispatch(loadAnimationAsync(filename));
        }
      }
    }, [dispatch, animationStatus, animationFiles]);

    function handleNext() {
        if (step < textKeys.length - 1) {
            navigate(`/participation/${surveyid}/${step + 1}`);
        }
    }

    return (
        <Layout layoutClass="layout-giveback layout-ap">
            <div className='background-container padded-panel'>
                { animation &&  <div className='lottie-container'>
                    <Lottie className="lottie" animationData={animation} loop={true} />
                    <div className='frame-overlay'></div>
                </div>}
            </div>
            <div className={`section-ap-giveback ap-survey z-front content-panel padded-panel`}>
                { !hasPhoneLayout && <AppHeader surveyType={surveyClientData.surveyType} /> }
                { didYouKnow && <div className={styles.didYouKnow}>
                    <div className='question main-title'>{ localeStrings['didYouKnow'] }</div>
                    </div> }
                <div className={`question-container step-${step} ${questionStyles.join(' ')}`}>
                    <div className="question-large" dangerouslySetInnerHTML={{__html: localeStrings[textKeys[step]] }}></div>
                    { step < firstBg2Index && <div className={`delay-reveal main-subtitle ${styles.secondaryText}`}>{ localeStrings['apGiveback1b'] }</div> }
                    { step < textKeys.length - 1 && <div className={`right-aligned clickable ${step < firstBg2Index ? 'delay-reveal' : ''}`}
                        onClick={() => handleNext()} >
                            <RightArrow />
                        </div> }
                </div>
                { step === textKeys.length - 1 && 
                    <div className={`${styles.finalContainer} ${questionStyles.join(' ')}`}>
                        <div className='centered'>
                            <a href={ONOP_ARTS_HEALTH_URL} className="onop-button anchor-button">
                            { localeStrings['clickHere'] }</a>
                        </div>
                        <div className='question-container step-final second-question'>
                            <div className="question-large">{ localeStrings['apGivebackJoin'] }</div>
                        </div>
                        <div className={`centered ${styles.secondButtonContainer}`}>
                            <a href={ONOP_INTEREST_SURVEY_URL} className="onop-button anchor-button additional-button">
                            { localeStrings['clickHere'] }</a>
                        </div>
                       <button className={`secondary-button ${styles.creditsButton}`}
                            aria-label={ localeStrings['credits'] }
                            onClick={() => navigate("/credits")}>
                            { localeStrings['credits'].toLowerCase() }
                        </button>
                    </div> }
            </div>
        </Layout>
    );
  }