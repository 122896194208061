
import {QuestionClientDataMap, SurveyClientData} from './types';

export function getClientDataForSurvey(surveyId: string): SurveyClientData {
    return SURVEY_CLIENTDATA_MAP[surveyId] ?? SURVEY_CLIENTDATA_MAP['default'];
}

const TOC_SECTION_ORDER = ['participation', 'cohesion', 'wellbeing', 'perceptions', 'demographics'];
const AP_SECTION_ORDER = ['participation', 'demographics'];

const TOC_BLOCK_NAMES: { [key: string]: string } = {
    'BL_5z5rR7S2KUHl66y': 'demographics',
    'BL_8wU6X1euK8e4vHg': 'perceptions',
    'BL_cJi54GuOIhD4kfk': 'wellbeing',
    'BL_eJWmLUqKWvLONuK': 'participation',
    'BL_9za6OkYID4sKL3M': 'cohesion',
  }

const AP_BLOCK_NAMES: { [key: string]: string } = {
    'BL_e2R6EnAIoxALdH0': 'demographics',
    'BL_cuSKCmO61GMoLu6': 'participation',
}


// for Theory of Change survey
const TOC_DEFAULT_SURVEY_DATA = { 
    allowBackButton: false,
    lastQuestionId: 'QID31',
    consentQuestionId: 'QID33',
    startQuestionId: 'QID3',
    zipQuestionId: 'QID32',
    participationIntroId: 'QID3',
    participationYearId: 'QID5',
    givebackPath: '/data', 
};

const TOC_SURVEY_QUESTION_DATA: QuestionClientDataMap = {
    'QID3': {
        bgFileType: 'json',
        animation: 'S1-0.json',
    },
    'QID4': {
        bgFileType: 'json',
        animation: 'S1-Q1.json',
        secondaryText: {
            'en': 'Using your keyboard,\ntype in a number between 0 and 30.',
            'es': 'Usando su teclado,\ntypo un número entre 0 y 30.',
        },
        inputType: 'number',
        hasSlider: true,
    },
    'QID5': {
        bgFileType: 'json',
        animation: 'S1-Q2.json',
        secondaryText: {
            'en': 'Using your keypad,\ntype in a number between 0 and 365.',
            'es': "Usando su teclado,\ntypo un número entre 0 y 365.",
        },
        inputType: 'number',
        hasSlider: true,
    },
    'QID6': {
        bgFileType: 'json',
        animation: 'S2-0.json',
        questionText: {
            'en': 'Please read the following definition for "community":',
        },
        secondaryText: {
            'en': 'For this survey, we refer to "community" as a group of people living in a shared physical, or geographic, area. Your community may include groups of people with both common and different backgrounds, identities, or ties and may include transient, or temporary, residents such as migrant or student groups.\n\nPlease respond to the survey questions with this definition and your community in mind.',
        },
        textClass: 'static-text-small',
    },
    'QID7': {
        bgFileType: 'json',
        animation: 'S2-Q1.json',
        choicesReversed: true,
    },
    'QID8': {
        bgFileType: 'json',
        animation: 'S2-Q2.json',
        choicesReversed: true,
    },
    'QID9': {
        bgFileType: 'json',
        animation: 'S2-Q3.json',
        choicesReversed: true,
    },
    'QID10': {
        bgFileType: 'json',
        animation: 'S2-Q4.json',
        alignment: 'bottom',
        choicesReversed: true,
    },
    'QID11': {
        bgFileType: 'json',
        animation: 'S2-Q5.json',
        choicesReversed: true,
    },
    'QID12': {
        bgFileType: 'json',
        animation: 'S2-Q6.json',
        alignment: 'bottom',
        choicesReversed: true,
    },
    'QID13': {
        bgFileType: 'json',
        animation: 'S2-Q7.json',
        choicesReversed: true,
    },
    'QID14': {
        bgFileType: 'json',
        animation: 'S2-Q8.json',
        choicesReversed: true,
    },
    'QID15': {
        bgFileType: 'json',
        animation: 'S2-Q9.json',
        choicesReversed: true,
    },
    'QID16': 
    {
        bgFileType: 'json',
        animation: 'S2-Q10.json',
        alignment: 'middle',
        choicesReversed: true,
    },
    'QID17': 
    {
        bgFileType: 'json',
        animation: 'S3-0.json',
        questionText: {
            'en': 'Please respond to each item in this section by selecting one answer choice per question regarding how you felt in the last two weeks.',
        }
    },
    'QID18': 
    {
        bgFileType: 'json',
        animation: 'S3-Q1.json',
    },
    'QID19': 
    {
        bgFileType: 'json',
        animation: 'S3-Q2.json',
    },
    'QID20': 
    {
        bgFileType: 'json',
        animation: 'S3-Q3.json',
    },
    'QID21': 
    {
        bgFileType: 'json',
        animation: 'S3-Q4.json',
    },
    'QID22': 
    {
        bgFileType: 'json',
        animation: 'S3-Q5.json',
    },
    'QID23': 
    {
        bgFileType: 'json',
        animation: 'S4-Q1.json',
        alignment: 'middle',
        choicesReversed: true,
    },
    'QID25': 
    {
        bgFileType: 'json',
        animation: 'S4-Q2.json',
        alignment: 'middle',
        choicesReversed: true,
    },
    'QID26': 
    {
        bgFileType: 'json',
        animation: 'S5-Q1.json',
        buttonClass: 'onop-button-smallprint',
        questionText: {
            'en': 'How would you describe your race, ethnicity or origin?',
        },
        navLabels: {
            'en': ['Please choose all that apply.'],
            'es': ['Por favor elija todas las que correspondan.'],
        },
        textEntryPrompt: {
            'en': 'I prefer to self-describe as:',
        },
        exclusiveChoices: ['8', '9'],
        scrollArrow: true,
    },
    'QID27': 
    {
        bgFileType: 'json',
        animation: 'S5-Q1.json',
    },
    'QID28': 
    {
        bgFileType: 'json',
        animation: 'S5-Q2.json',
        questionText: {
            'en': 'What gender identity(ies) do you currently use to describe yourself?',
        },
        navLabels: {
            'en': ['Please choose all that apply.'],
            'es': ['Por favor elija todas las que correspondan.'],
        },
        textEntryPrompt: {
            'en': 'I prefer to self-describe as:',
        },
        exclusiveChoices: ['5', '6'],
        buttonClass: 'onop-button-smallprint',
        scrollArrow: true,
    },
    'QID29': 
    {
        bgFileType: 'json',
        animation: 'S5-Q3.json',
        inputType: 'number',
        secondaryText: {
            'en': "Using your keyboard,\ntype in a number between 18 and 110.",
            'es': "Usando su teclado,\ntypo un número entre 18 y 110.",
        },
    },
    'QID30': 
    {
        bgFileType: 'json',
        animation: 'S5-Q4.json',
    },
    'QID31': 
    {
        bgFileType: 'json',
        animation: 'S5-Q5.json',
    },
    'QID32':
    {
        questionText: {
            'en': 'Please enter your zip code',
        },
    },
    'QID35': 
    {
        bgFileType: 'json',
        animation: 'S4-0.json',
        questionText: {
            'en': 'Please select the extent to which you agree with the statements in the following questions.'
        } 
    },
    'QID37':
    {
        bgFileType: 'json',
        animation: 'S5-0.json',
    }
  }

  // for Arts Participation survey (short survey)
  const AP_DEFAULT_SURVEY_DATA = { 
    allowBackButton: false,
    displaySectionCounter: false,
    displaySectionFinished: false,
    givebackPath: '/participation',
    lastQuestionId: 'QID11',
    consentQuestionId: 'QID12',
    startQuestionId: 'QID13',
    zipQuestionId: 'QID14',
    participationQuestion1: 'QID1',
    participationQuestion2: 'QID3',
    participationQuestion3: 'QID4',
  };

  const AP_SURVEY_QUESTION_DATA: QuestionClientDataMap = {
    'QID1': {
        bgFileType: 'json',
        animation: 'S2-0.json',
        contentType: 'html',
        noDelay: true,
        section: 'cohesion',
    },
    'QID3': {
        bgFileType: 'json',
        animation: 'S3-0.json',
        noDelay: true,
        section: 'wellbeing',
    },
    'QID4': {
        bgFileType: 'png',
        animation: 'S4-0.json',
        noDelay: true,
        section: 'perceptions',
    },
    'QID5': {
        contentType: 'html',
        bgFileType: 'json',
        animation: 'S1-0.json',
        noDelay: true,
        questionText: {
            //'en': 'Please respond to the following statements by selecting YES or NO',
            'en': 'Please share your views on the following statements. In the first statement, "social cohesion" refers to the strength of relationships among members of a community.',
        },
    },
    'QID6': {
        bgFileType: 'json',
        animation: 'S5-Q1.json',
        exclusiveChoices: ['19', '20'],
        buttonClass: 'onop-button-smallprint',
        questionText: {
            'en': 'How would you describe your race, ethnicity or origin?',
        },
        navLabels: {
            'en': ['Please choose all that apply.'],
            'es': ['Por favor elija todas las que correspondan.'],
        },
        textEntryPrompt: {
            'en': 'I prefer to self-describe as:',
        },
        scrollArrow: true,
    },
    'QID8': {
        bgFileType: 'json',
        animation: 'S5-Q2.json',
        questionText: {
            'en': 'What gender identity(ies) do you currently use to describe yourself?',
        },
        navLabels: {
            'en': ['Please choose all that apply.'],
            'es': ['Por favor elija todas las que correspondan.'],
        },
        textEntryPrompt: {
            'en': 'I prefer to self-describe as:',
        },
        exclusiveChoices: ['21', '22'],
        buttonClass: 'onop-button-smallprint',
        scrollArrow: true,
    },
    'QID9': {
        bgFileType: 'json',
        animation: 'S5-Q3.json',
        inputType: 'number',
        secondaryText: {
            'en': 'Using your keypad,\ntype in a number between 18 and 110',
        },
    },
    'QID10': {
        bgFileType: 'json',
        animation: 'S5-Q4.json',
    },
    'QID11': {
        bgFileType: 'json',
        animation: 'S5-Q5.json',
    },
    'QID12': {
        useInCounter: false,
    },
    'QID13': {
        bgFileType: 'json',
        animation: 'S1-Q1.json',
        contentType: 'html',
        useInCounter: false,
        useSurveyHeader: true,
        noDelay: true,
        section: 'intro',
        navLabels: {
            'en': ['Tap the right arrow to continue.'],
        },
        questionText: {
            'es': '¿Has participado en las artes en los últimos 7 días?',
        }
    },
    'QID14': {
        useInCounter: false,
        contentType: 'html',
        questionText: {
            'es': '¿Cuál es su código postal?',
        },
        section: 'intro',
    },
    'QID15': {
        bgFileType: 'json',
        animation: 'S5-0.json',
    },
    'QID16': {
        bgFileType: 'json',
        animation: 'S5-0.json',
    }
  };

  export const SURVEY_CLIENTDATA_MAP: { [key: string]: SurveyClientData } = {
    'SV_6eZB95xzbYdxPsG': {
        surveyType: 'toc',
        ...TOC_DEFAULT_SURVEY_DATA,
        questionData: TOC_SURVEY_QUESTION_DATA,
        sectionOrder: TOC_SECTION_ORDER,
        blockNames: TOC_BLOCK_NAMES,
    },
    'SV_2gTK71igWkyBBEq': {
        surveyType: 'toc',
        ...TOC_DEFAULT_SURVEY_DATA,
        questionData: TOC_SURVEY_QUESTION_DATA,
        sectionOrder: TOC_SECTION_ORDER,
        blockNames: TOC_BLOCK_NAMES,
    },
    'SV_9nLwrvytQwL70O2': {
        ...AP_DEFAULT_SURVEY_DATA,
        surveyType: 'ap',
        questionData: AP_SURVEY_QUESTION_DATA,
        sectionOrder: AP_SECTION_ORDER,
        blockNames: AP_BLOCK_NAMES,
    },
    'SV_bHJFVSONGYynDIG': {
        ...AP_DEFAULT_SURVEY_DATA,
        surveyType: 'ap',
        questionData: AP_SURVEY_QUESTION_DATA,
        sectionOrder: AP_SECTION_ORDER,
        blockNames: AP_BLOCK_NAMES,
    },
    'SV_82goB1FzE0tIcho': {
        ...AP_DEFAULT_SURVEY_DATA,
        surveyType: 'ap',
        questionData: AP_SURVEY_QUESTION_DATA,
        sectionOrder: AP_SECTION_ORDER,
        blockNames: AP_BLOCK_NAMES,
    },
    'default': {
        surveyType: 'toc',
        ...TOC_DEFAULT_SURVEY_DATA,
        questionData: TOC_SURVEY_QUESTION_DATA,
        sectionOrder: TOC_SECTION_ORDER,
        blockNames: TOC_BLOCK_NAMES,
    },
  }
