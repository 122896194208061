import Lottie from "lottie-react";

import styles from './Loading.module.scss';

import {
    selectLocaleStrings,
  } from '../features/survey/surveySlice';
import { useAppSelector } from '../app/hooks';

import loadingAnimation from "../animations/loading.json";
import splashImage from '../images/AFEBlogo.png';

export function Loading() {
    const localeStrings = useAppSelector(selectLocaleStrings);
    
    return (
        <>
            <div className={`section-intro centered framed-container`}>
                  <div className={styles.imageContainer}><img src={splashImage} alt="Arts for Everybody" /></div>
            </div>
            <div className='content-panel'>
              <div className={styles.animationContainer}>
                <Lottie className="lottie" animationData={loadingAnimation} loop={true} />
              </div>
              <div className={`z-front padded-panel ${styles.loadingContainer}`}>
                <div className={styles.loading}>{ localeStrings['surveyLoading'] }</div>
              </div>
            </div>
        </>
    );
  }