import { openDB, DBSchema } from 'idb';
import { SurveyResponseSet } from './types';

const DATABASE_NAME = 'onopSurveyDB';
const RESPONSE_STORE_NAME = 'responses';

interface SurveyDB extends DBSchema {
  responses: {
    key: string,
    value: SurveyResponseSet;
    indexes: { 'surveyId': string}
  }
}

// from https://web.dev/learn/pwa/offline-data/
// using IDB from https://github.com/jakearchibald/idb
function createDB() {
  return openDB<SurveyDB>(DATABASE_NAME, 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
      // Switch over the oldVersion, *without breaks*, to allow the database to be incrementally upgraded.
    switch(oldVersion) {
      case 0:
        // Placeholder to execute when database is created (oldVersion is 0)
        // no break;
      case 1:
        // Create a store of objects
        const store = db.createObjectStore(RESPONSE_STORE_NAME, {
          // The `id` property of the object will be the key, and be incremented automatically
          keyPath: 'sessionId'
        });
        // Create an index called `surveyId` based on the `surveyId` property of objects in the store
        store.createIndex('surveyId', 'surveyId');
        break;
      }
    }
  });
}

export async function storeResponsesLocally(responseSet: SurveyResponseSet) {
  const db = await createDB();
  const tx = db.transaction(RESPONSE_STORE_NAME, 'readwrite');
  const store = tx.objectStore(RESPONSE_STORE_NAME);
  store.put(responseSet);
  await tx.done;
}

export async function getAllLocalResponses(): Promise<SurveyResponseSet[]> {
  const db = await createDB();
  const tx = db.transaction(RESPONSE_STORE_NAME, 'readwrite');
  const store = tx.objectStore(RESPONSE_STORE_NAME);
  const results = await store.getAll();
  return results;
}

export async function getLocalResponse(sessionId: string): Promise<SurveyResponseSet|undefined> {
  const db = await createDB();
  const tx = db.transaction(RESPONSE_STORE_NAME, 'readwrite');
  const store = tx.objectStore(RESPONSE_STORE_NAME);
  const result = await store.get(sessionId);
  return result;
}

export async function deleteLocalResponse(sessionId: string): Promise<void> {
  const db = await createDB();
  const tx = db.transaction(RESPONSE_STORE_NAME, 'readwrite');
  const store = tx.objectStore(RESPONSE_STORE_NAME);
  await store.delete(sessionId);
}

