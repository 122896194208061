import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import useMousePosition from "../hooks/useMousePosition";
import { useAppSelector } from '../app/hooks';

import { Layout } from '../layouts/Layout';
import { AppHeader } from './AppHeader';
import { SMALL_DESKTOP_BREAKPOINT } from '../constants';
import {
    selectLocaleStrings,
    selectHasPhoneLayout,
    selectLayoutType,
  } from '../features/survey/surveySlice';
import './Draw.scss';
import human0Json from "../animations/S0-human-test.json";
import human1Json from "../animations/S0-human.json";
import desktop0Json from "../animations/desktop/S0-human-test.json";
import desktop1Json from "../animations/desktop/S0-human.json";

interface Point {
    x: number,
    y: number,
}

interface Path {
    points: Point[];
}

export function Draw() {
    const animationRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [coords, handleCoords] = useMousePosition(true);
    const [canvasOrigin, setCanvasOrigin] = useState<Point>({ x: 0, y: 0});
    const [paths, setPaths] = useState<Path[]>([]);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [isHuman, setIsHuman] = useState<boolean>(false);
    const navigate = useNavigate();
    const localeStrings = useAppSelector(selectLocaleStrings);
    const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);
    const layoutType = useAppSelector(selectLayoutType);
    const animationJson = isHuman ? (hasPhoneLayout ? human1Json : desktop1Json) : 
        (hasPhoneLayout ? human0Json : desktop0Json);
    let scale = (layoutType === 'desktop' ? 1.2 : 1);
           
    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.clearRect(0, 0, 1000, 1000);
                ctx.lineWidth = 2;

                for (const path of paths) {
                    ctx.beginPath();
                    ctx.moveTo(path.points[0].x, path.points[0].y);
                    for (let i=1; i < path.points.length; ++i) {
                        ctx.lineTo(path.points[i].x, path.points[i].y);
                    }
                    ctx.stroke();
                }

                // clipping region
                const clip = [
                    { x: 0, y: 32},
                    { x: 50, y: 15},
                    { x: 100, y: 5},
                    { x: 200, y: 0},
                    { x: 264, y: 164},
                    { x: 145, y: 184},
                    { x: 135, y: 220},
                    { x: 100, y: 232},
                    { x: 10, y: 50},
                    { x: 0, y: 32}
                ]
                ctx.beginPath();
                ctx.moveTo(clip[0].x * scale, clip[0].y * scale);
                for (let i=1; i < clip.length; ++i) {
                    ctx.lineTo(clip[i].x * scale, clip[i].y * scale);
                }
                // DEBUG:
                // ctx.stroke();
                ctx.clip();
            }
        }
    }, [canvasRef, paths, scale]);

    // Register the event listeners
    useEffect(() => {
        const canvas = canvasRef.current;
        
        // in this view, we need to detect small desktop heights
        const smallDesktopMq = window.matchMedia(`(max-height: ${SMALL_DESKTOP_BREAKPOINT-1}px)`);

        const handleDocumentTouch = (e: TouchEvent) => {
             e.preventDefault();
        }
        if (canvas) {
            canvas.addEventListener("touchstart", handleDocumentTouch);
            canvas.addEventListener("touchmove", handleDocumentTouch);
            canvas.addEventListener("touchend", handleDocumentTouch);

            let x = 52;     // defaults for phone
            let y = 250;
            let scale = 1;
            
            if (layoutType === 'tablet') {
                x = 82;
                y = 128;
            }
            else if (layoutType === 'desktop') {
                x = 92;
                y = 164; 
                scale = 1.2;

                if (smallDesktopMq.matches) {
                    // smaller margin on small desktop view
                    y = 132;
                }
            }
            
            // set its size so it fits within the parent animation
            const ctx = canvas.getContext("2d");
            if (animationRef.current && ctx) {
                // scale = animationRef.current.offsetWidth / 384;
                canvas.style.left = `${scale * x}px`;
                canvas.style.top = `${scale * y}px`;
                ctx.canvas.width = scale * 264;
                ctx.canvas.height = scale * 232;
            }
        }
        
        // cleanup if we unmount
        return () => {
            canvas?.removeEventListener('touchstart', handleDocumentTouch);
            canvas?.removeEventListener('touchmove', handleDocumentTouch);
            canvas?.removeEventListener('touchend', handleDocumentTouch);
        }
    }, [canvasRef, animationRef, layoutType, scale]);
 
    // Update canvas position
    useEffect(() => {
        if (canvasRef?.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            setCanvasOrigin({x: rect.x, y: rect.y});
        }
    }, [canvasRef]);

    /*
    function clearCanvas() {
        setPaths([]);
        setIsHuman(false);
    } */

    function validate(paths: Path[]): boolean {
        if (paths.length > 2) {
            // advance after three touches
            setIsHuman(true);
            return true;
        }
       
        return false;           
    }
    
    return (
        <Layout layoutClass="layout-intro">
          <div className="draw-lottie-container">
            <Lottie className="draw-lottie" animationData={animationJson} loop={false} />
          </div>
          <div className="drawing-container left-panel">
            { isHuman ? 
             <>
                { hasPhoneLayout && <div className="drawing-human question-large">
                    { localeStrings['drawingNotRobot'] }</div> }
                { hasPhoneLayout && <div className="validated-check">
                    <svg width="111" height="109" id="checkmark-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.56 109.05"><path fill="currentColor" d="m38.12,107.92c-3.12,1.14-6.46-5.04-8.81-6.88-5.4-5.53-12.53-10.93-19.07-14.54-6.82-3.62-11.74-4.98-7.42-13.72,2.42-4.67,5.02-12.3,7.87-15.53,2.25-1.7,4.91,3.75,6.42,5.36,3.16,4.22,6.8,9.1,10.07,13.46,3.14,4.13,5.28,7.18,7.98,10.57,3.4,4.44,4.2-1.27,5.36-3.77,8.77-23.47,23.24-55.41,42.17-75.08,10.88-12.54,12.27-6.23,20.3,4.38,2.35,3.48,6.82,8.46,7.45,11.95.36,2.39-1.25,3.83-3.94,5.38-18.3,9.37-35.76,27.11-45.73,42.66-4.15,5.99-7.89,11.69-11.56,17.96-2.12,3.61-4.35,7.69-6.57,11.99-1.05,1.96-2.65,4.98-4.41,5.77l-.1.04Z"/></svg>
                </div> }
             </> : 
             <>
                { hasPhoneLayout && <div className="drawing-intro question-large">{ localeStrings['drawingTitle'] }</div> }
                <div className="drawing-instructions">{ localeStrings['drawingInstruction'] }</div>
             </>
            }
            <div ref={animationRef} className="drawing-animation-container">
                <div className={`canvas-container ${isHuman ? 'hidden' : ''}`}>
                    <canvas ref={canvasRef}
                        width="256" height="256"
                        onClick={(e) => {
                            handleCoords((e as unknown) as MouseEvent);
                            // setPaths([...paths, { points: [{ x: coords.x, y: coords.y }]}, ]);
                        }}
                        onMouseDown={(e) => {
                            handleCoords((e as unknown) as MouseEvent);
                            setIsMouseDown(true);
                            setPaths([...paths, { points: [{ x: coords.x, y: coords.y }]}, ]);
                        }}
                        onMouseMove={(e) => {
                            handleCoords((e as unknown) as MouseEvent);
                            if (isMouseDown) {
                                const newPoint: Point = { x: coords.x, y: coords.y };
                                const updatedPath: Path = { points: [...paths[paths.length-1].points]};
                                updatedPath.points.push(newPoint);
                
                                setPaths([...paths.slice(0, paths.length - 1), updatedPath, ]);
                            }
                        }}
                        onMouseUp={(e) => {
                            handleCoords((e as unknown) as MouseEvent);
                            setIsMouseDown(false);
                            if (validate(paths)) {
                                setTimeout(() => { navigate(`/zip`);}, 3000);
                            }
                        }}
                        onTouchStart={(e) => {
                            var touch = e.touches[0];
                            const point = {
                                x: touch.clientX - canvasOrigin.x,
                                y: touch.clientY - canvasOrigin.y
                            };
                            setIsMouseDown(true);
                            setPaths([...paths, { points: [point]}, ]);
                        }}
                        onTouchMove={(e) => {
                            var touch = e.touches[0];
                            if (isMouseDown) {
                                const newPoint: Point = {
                                    x: touch.clientX - canvasOrigin.x, 
                                    y: touch.clientY - canvasOrigin.y
                                };
                                const updatedPath: Path = { points: [...paths[paths.length-1].points]};
                                updatedPath.points.push(newPoint);
                
                                setPaths([...paths.slice(0, paths.length - 1), updatedPath, ]);
                            }
                        }}
                        onTouchEnd={(e) => {
                            setIsMouseDown(false);
                            if (validate(paths)) {
                                setTimeout(() => { navigate(`/zip`);}, 3000);
                            }
                        }}
                        >
                    </canvas>
                </div>
                { !isHuman && <div className="drawing-footnote">{ localeStrings['drawingFootnote'] }</div> }
            </div>
          </div>
          { !hasPhoneLayout && <div className="right-panel padded-panel">
            <AppHeader surveyType="toc"></AppHeader>
            { isHuman ? 
             <>
                <div className="drawing-human question-large">
                    { localeStrings['drawingNotRobot'] }</div>
                <div className="validated-check">
                    <svg width="111" height="109" id="checkmark-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.56 109.05"><path fill="currentColor" d="m38.12,107.92c-3.12,1.14-6.46-5.04-8.81-6.88-5.4-5.53-12.53-10.93-19.07-14.54-6.82-3.62-11.74-4.98-7.42-13.72,2.42-4.67,5.02-12.3,7.87-15.53,2.25-1.7,4.91,3.75,6.42,5.36,3.16,4.22,6.8,9.1,10.07,13.46,3.14,4.13,5.28,7.18,7.98,10.57,3.4,4.44,4.2-1.27,5.36-3.77,8.77-23.47,23.24-55.41,42.17-75.08,10.88-12.54,12.27-6.23,20.3,4.38,2.35,3.48,6.82,8.46,7.45,11.95.36,2.39-1.25,3.83-3.94,5.38-18.3,9.37-35.76,27.11-45.73,42.66-4.15,5.99-7.89,11.69-11.56,17.96-2.12,3.61-4.35,7.69-6.57,11.99-1.05,1.96-2.65,4.98-4.41,5.77l-.1.04Z"/></svg>
                </div>
             </> : 
             <>
                <div className="drawing-intro question-large">{ localeStrings['drawingTitle'] }</div>
             </>
            }
            </div> }
        </Layout>
      );
}
