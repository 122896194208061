import axios from 'axios'
import { ResponseMap, SurveyResponseSet } from './types';
import { CLIENT_ID, REMOTE_HOST, QUALTRICS_BASE_PATH, USE_SURVEY_CACHE } from '../../constants';

// ToC
import SV_6eZB95xzbYdxPsG from '../../data/SV_6eZB95xzbYdxPsG.json';
// AP
import SV_9nLwrvytQwL70O2 from '../../data/SV_9nLwrvytQwL70O2.json';

const instance = axios.create({
  headers: {
    "onop-client-auth": 'tbd',
  },
});

let isRefreshing = false;
let accessToken: string|undefined;
let refreshSubscribers: Array<(token: string) => void> = [];

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const { config, response: { status } } = error;
    const originalRequest = config;

    // Qualtrics returns error 400 when token not set, check hasToken also
    if ((status === 401 || !accessToken) && !originalRequest._retry) {

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token: string) => {
          // retry with new token in header
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          originalRequest._retry = true;

          resolve(axios(originalRequest));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;
        const authResponse = await fetchToken(true);
        isRefreshing = false;
        onRefreshed( authResponse?.data?.access_token ?? '');
      }

      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  }
);

function subscribeTokenRefresh(cb: (token: string) => void) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string) {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
}

// Gets OAuth Token from Qualtrics
export async function fetchToken(refresh = false) {

  if (accessToken && !refresh) {
    // we already have a token
    return { data: { accessToken }};
  }

  const isDevEnvironment = process.env.NODE_ENV === 'development';
  const tokenUrl = isDevEnvironment ? `https://${REMOTE_HOST}/auth/token.php` : '/auth/token.php';
  
  try {
    const authResponse = await instance.post(tokenUrl, { client: CLIENT_ID });
    const newToken: string = authResponse?.data?.access_token;

    if (newToken) {
      // save token in headers
      instance.defaults.headers.common["Authorization"] =`Bearer ${newToken}`;
      accessToken = newToken;
    }

    return authResponse;
  } catch (e: unknown) {
    console.error('fetchToken failed');
    return undefined;
  }
}

// Loads animation json from URL
export function fetchAnimation(file: string) {
  return instance.get(`/json/${file}`);
}

// Loads survey info from Qualtrics
export function fetchSurvey(surveyId: string) {
  if (USE_SURVEY_CACHE) {
    // use cached JSON for the two known ONOP surveys
    if (surveyId === 'SV_9nLwrvytQwL70O2') {
      // Arts Participation
      return {
        data: SV_9nLwrvytQwL70O2,
      };
    }
    if (surveyId === 'SV_6eZB95xzbYdxPsG') {
      // Theory of Change
      return {
        data: SV_6eZB95xzbYdxPsG,
      };
    }
  }

  return instance.get(
    `${QUALTRICS_BASE_PATH}survey-definitions/${surveyId}`);
}

// Loads survey questions from Qualtrics
export function fetchSurveyQuestions(surveyId: string) {
  return instance.get(
    `${QUALTRICS_BASE_PATH}survey-definitions/${surveyId}/questions`);
}

// Loads an existing survey response session
export function loadSession(surveyId: string, sessionId: string) {
  return instance.get(
    `${QUALTRICS_BASE_PATH}surveys/${surveyId}/sessions/${sessionId}`);
}

// Starts a new survey response session
export function startSession(surveyId: string, language = "EN") {
  // TODO: add auth token to axios defaults
  // this.axios.defaults.headers.common["Authorization"] =`Bearer ${token}`;
  return instance.post(
    `${QUALTRICS_BASE_PATH}surveys/${surveyId}/sessions`,
  {
    language,
  });
}

// Submits a block of responses within a survey session
export function submitResponses(surveyId: string, sessionId: string, responses: ResponseMap, close = false) {
  const payload = close ? {
    close: true,
    responses,
  } : {
    advance: true,
    responses,
  }
  return instance.post(
    `${QUALTRICS_BASE_PATH}surveys/${surveyId}/sessions/${sessionId}`, payload);
}

// Submits an entire set of responses
export function submitResponseSet(responseSet: SurveyResponseSet, useProxy = false) {
  const headers = {
    "Idempotency-Key": responseSet.sessionId,
  };
  if (useProxy) {
    return instance.post(
      `/surveys/responses.php`,
      { values: responseSet.qualtricsResponses, 
        client: CLIENT_ID,
        surveyId: responseSet.surveyId,
        idempotencyKey: responseSet.sessionId },
      { headers });
  }
  else {
    return instance.post(
      `${QUALTRICS_BASE_PATH}surveys/${responseSet.surveyId}/responses`,
      { values: responseSet.qualtricsResponses },
      { headers });
  }
}

// For debugging: gets one set of responses
export function getResponseSet(surveyId: string, responseId: string) {
  return instance.get(
    `${QUALTRICS_BASE_PATH}surveys/${surveyId}/responses/${responseId}`);
}

// Send update to finish a survey session
export function finishSession(surveyId: string, sessionId: string) {
  return instance.post(
    `${QUALTRICS_BASE_PATH}surveys/${surveyId}/sessions/${sessionId}`,
  {
    close: true,
  });
}
