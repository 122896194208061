import React from 'react';
import styles from './Layout.module.scss';

type Props = {
    title?: string,
    children?: React.ReactNode,
    layoutClass?: string,
  };

export const Layout: React.FC<Props> = ({
    children,
    layoutClass,
  }) => (
    <div className={`${styles.main} ${layoutClass ?? styles['main-default']}`}>
        <div className={`${styles.content}`}>
         {children}
        </div>
      </div>
  );
