import React from 'react';
import { useAppSelector } from '../app/hooks';
import { useParams } from "react-router-dom";
import splashImage from '../images/AFEBlogo.png';
import styles from './Ineligible.module.scss';

import {
  selectLocaleStrings,
  selectErrorMessage,
  selectHasPhoneLayout,
} from '../features/survey/surveySlice';

import { Layout } from '../layouts/Layout';
import { AppHeader } from './AppHeader';
import { ONENATIONONEPROJECT_URL } from '../constants';

export function Ineligible(props: { reason?: string }) {
  let { surveytype } = useParams();
  const { reason } = props;
  const localeStrings = useAppSelector(selectLocaleStrings);
  const errorMessage = useAppSelector(selectErrorMessage);
  const hasPhoneLayout = useAppSelector(selectHasPhoneLayout);

  let message = localeStrings[`${surveytype ?? 'toc'}Ineligible`];
  let message2 = '';
  if (reason === 'error' && errorMessage) {
    if (localeStrings[errorMessage]) {
      message = localeStrings[errorMessage];
    }
    else { 
      message = errorMessage;
    }
  }
  else if (reason === 'closed') {
    message = localeStrings['closedSurvey'];
    message2 = localeStrings['closedSurvey2'];
  }
  
  return (
    <Layout>
        { hasPhoneLayout ? 
          <>
            <AppHeader surveyType={surveytype}  />
            <div className={`question-container ${surveytype}-survey section-intro`}>
              <div className="message-text">{ message }</div>
              { message2 &&  <div className={`message-text ${styles.secondMessage}`} dangerouslySetInnerHTML={{__html: message2 }}></div> }
              <div className="action-buttons">
                <div><a href={ONENATIONONEPROJECT_URL} className="onop-button wide-button">Visit the website</a></div>
              </div>
              <div className={styles.imageContainer}>
              <a href={ONENATIONONEPROJECT_URL}><img src={splashImage} alt="Arts for Everybody" /></a></div>
            </div>
          </> :
           <>
            <div className={`${surveytype}-survey section-intro framed-container left-panel`}>
                <AppHeader surveyType={surveytype} />
                <div className={styles.messageContainer}>
                  <div className="message-text">{ message }</div>
                  { message2 &&  <div className="message-text" dangerouslySetInnerHTML={{__html: message2 }}></div> }
                </div>
            </div>
            <div className={`${styles.desktopRight} ${surveytype}-survey section-intro right-panel`}>
              <div className={styles.imageContainer}>
                  <a href={ONENATIONONEPROJECT_URL}><img src={splashImage} alt="Arts for Everybody" /></a>
              </div>
              <div className="action-buttons">
                <div><a href={ONENATIONONEPROJECT_URL} className="onop-button wide-button">Visit the website</a></div>
              </div>
            </div>
          </>
        }
    </Layout>
  );
}
