import React from 'react';
import { useAppSelector } from '../app/hooks';
import styles from './SectionCounter.module.scss';

import {
  selectClientData,
} from '../features/survey/surveySlice';

interface SectionCounterProps {
  blockName: string;
}

export function SectionCounter(props: SectionCounterProps) {
    const { blockName } = props;
    const surveyClientData = useAppSelector(selectClientData);

    return (
      <div className={styles.container}>
        <div className={styles.connector}></div>
        { surveyClientData.sectionOrder.map(section => <div key={section} className={`${ blockName === section ? styles.activeBlock : styles.block} ${styles[section]}`}></div>)}
      </div>
    );
  }
