import {BlockMap, QuestionChoice, QuestionMap, QuestionTranslationSet, ResponseMap, SurveyBlock, SurveyClientData, SurveyQuestion, SurveyQuestionSet, SurveyResponseSet, SurveyState} from './types';
import { SURVEY_CLIENTDATA_MAP } from './clientData';

// simple cache for animation data for Lottie
export const animationCache = new Map<string, any>();

function getQuestionTranslations(question: SurveyQuestion, language?: string): QuestionTranslationSet|undefined
{
  return (language && question.translations) ? 
    question.translations[language.toUpperCase()] : undefined;
}

export function getSurveyClientData(surveyId: string): SurveyClientData {
  return SURVEY_CLIENTDATA_MAP[surveyId] ?? SURVEY_CLIENTDATA_MAP['default'];
}

export function getQuestionText(question: SurveyQuestion, language?: string): string {
  const clientDataQuestion = question.clientData?.questionText && language ? question.clientData.questionText[language] : undefined;
  const translations = getQuestionTranslations(question, language);
  
  return clientDataQuestion ?? translations?.questionText ?? question.questionText;
}

export function getQuestionAtIndex(questionSet: SurveyQuestionSet|undefined, index: number): SurveyQuestion|undefined {          
  if (questionSet && index < questionSet.questionOrder.length) {
    return questionSet.questions[questionSet.questionOrder[index]];
  }

  return undefined;
}

export function getIndexOfQuestionId(questionSet: SurveyQuestionSet, questionId: string): number {
  const index = questionSet?.questionOrder.findIndex((id) => id === questionId);
  return index;
}

export function getIndexOfQuestion(questionSet: SurveyQuestionSet, question: SurveyQuestion): number {
  return getIndexOfQuestionId(questionSet, question.questionId);
}

export function getNextQuestion(questionSet: SurveyQuestionSet, question: SurveyQuestion): SurveyQuestion|undefined {
  const questionIndex = getIndexOfQuestion(questionSet, question);
  if (questionIndex >= 0 && questionIndex < questionSet.questionOrder.length - 1) {
    return getQuestionAtIndex(questionSet, questionIndex + 1);
  }

  return undefined;
}

export function getNextQuestionId(questionSet: SurveyQuestionSet, questionId: string): string|undefined {
  const questionIndex = getIndexOfQuestionId(questionSet, questionId);
  if (questionIndex >= 0 && questionIndex < questionSet.questionOrder.length - 1) {
    const nextQuestion = getQuestionAtIndex(questionSet, questionIndex + 1);
    if (nextQuestion) {
      return nextQuestion.questionId;
    }
  }

  return undefined;
}

export function getPreviousQuestion(questionSet: SurveyQuestionSet, question: SurveyQuestion): SurveyQuestion|undefined {
  const questionIndex = getIndexOfQuestion(questionSet, question);
   if (questionIndex > 0) {
     return getQuestionAtIndex(questionSet, questionIndex - 1);
   }
 
   return undefined;
 }

export function getQuestionChoices(question: SurveyQuestion, language?: string): QuestionChoice[] {
  if (question.questionType === 'MC') {
    const translations = getQuestionTranslations(question, language);
    const choices = translations?.choices ?? question.choices ?? [];

    return choices;
  } 

  return [];
}


// return the block that a question belongs to
export function getBlockForQuestion(state: SurveyState, questionId: string): SurveyBlock|undefined {
  for (const block of Object.values(state.blocks)) {
    if (block.questionIds.includes(questionId)) {
      return block;
    }
  }

  return undefined;
}

// if the question is the last question in a block, return that block
export function lastQuestionInBlock(state: SurveyState, questionId: string): SurveyBlock|undefined {
  for (const block of Object.values(state.blocks)) {
    if (block.questionIds.length > 0 &&
        questionId === block.questionIds[block.questionIds.length - 1]) {
        // is last question
            return block;
        }
   }

  return undefined;
}

// return true if the question is the last in the block
export function isLastInBlock(block: SurveyBlock, question: SurveyQuestion): boolean {
  return (block.questionIds.length > 0 &&
        question.questionId === block.questionIds[block.questionIds.length - 1]);
}

// return true if the question is the first in the block
export function isFirstInBlock(block: SurveyBlock, question: SurveyQuestion): boolean {
  return (block.questionIds.length > 0 &&
        question.questionId === block.questionIds[0]);
}

// returns booleans indicating which questions have responses in the block
export function getCompletedQuestionsInBlock(block: SurveyBlock, responseSet: SurveyResponseSet): boolean[] {
  return block.questionIds.map(id => responseSet.responses[id] !== undefined);
}

// separates answered and unanswered questions from a list
export function separateAnsweredQuestions(questions: SurveyQuestion[], responseSet: SurveyResponseSet): {
  answered: SurveyQuestion[],
  unanswered: SurveyQuestion[]
} {
  const answerableQuestions = questions.filter((question) => question.questionType !== 'DB');
  return {
    answered: answerableQuestions.filter((question) => responseSet.responses[question.questionId] !== undefined),
    unanswered: answerableQuestions.filter((question) => responseSet.responses[question.questionId] === undefined),
  };
}

// returns true if the block has unanswered questions
export function hasUnansweredQuestions(questions: SurveyQuestion[], responseSet: SurveyResponseSet): boolean {
  const answerableQuestions = questions.filter((question) => question.questionType !== 'DB');
  return answerableQuestions.some((question) => responseSet.responses[question.questionId] === undefined);
}

// returns true if the question has validation settings that are used in the app
export function hasValidation(question: SurveyQuestion, clientData: SurveyClientData): boolean {
  const validationSettings = question.validationSettings;
  if (validationSettings?.type === 'ValidNumber') {
    if (validationSettings.minimum !== undefined || validationSettings.maximum !== undefined) {
      return true;
    }
  }

  if (question.questionId === clientData.zipQuestionId) {
    return true;
  }

  return false;
}

// returns animation path for the current layout
export function getAnimationPath(filename: string | undefined, hasPhoneLayout: boolean): string | undefined {
  if (!filename || hasPhoneLayout) {
    return filename;
  }

  // desktop
  return 'desktop/' + filename;
}

// returns a set of question blocks from the survey data returned from Qualtrics
export function loadBlocksFromSurvey(survey: any): BlockMap {
  const clientData = getSurveyClientData(survey.SurveyID);
  const blocks: BlockMap = {};
  for (const key of Object.keys(survey.Blocks)) {
    const element: any = survey.Blocks[key];
    if (element.Type.toLowerCase() !== "trash") {
      const name = clientData.blockNames[element.ID];
      blocks[element.ID] = {
        blockId: element.ID,
        description: element.Description,
        questionIds: element.BlockElements.map((blockElement: any) => blockElement.QuestionID).filter((id: string) => id !== undefined),
        name,
        sectionIndex: clientData.sectionOrder.indexOf(name),
      }
    }
  }

  return blocks;
}

export function loadQuestionSetFromSurvey(surveyId: string, survey: any, blocks: BlockMap): SurveyQuestionSet {
  const questionSet: SurveyQuestionSet = {
    surveyId: surveyId,
    questions: loadQuestionsFromSurvey(survey, surveyId),
    questionOrder: [],
  };
  
  // get question order from blocks
  let index = 0;
  for (const flow of survey.SurveyFlow.Flow) {
    if (flow.Type === 'Standard' || flow.Type === 'Block') {
      questionSet.questionOrder = [
        ...questionSet.questionOrder,
        ...blocks[flow.ID].questionIds,
      ];
      blocks[flow.ID].blockIndex = index;
    }
    index += 1;
  }

  return questionSet;
}

// returns a set of questions from the survey data returned from Qualtrics
export function loadQuestionsFromSurvey(survey: any, surveyId: string): QuestionMap {
  const questions: QuestionMap = {};
  const clientData = getSurveyClientData(surveyId);

  for (const key of Object.keys(survey.Questions)) {
    const element = survey.Questions[key];
    let questionText = element.QuestionText;
    const question: SurveyQuestion = {
      questionId: element.QuestionID,
      questionText,
      questionType: element.QuestionType,
      clientData: clientData?.questionData[element.QuestionID],
    };
    if (element.Choices) {
      const choices = element.Choices;
      question.choices = [];
      for (const choiceIndex of element.ChoiceOrder) {
        const newChoice: QuestionChoice = {
          value: `${choiceIndex}`,
          display: choices[choiceIndex].Display,
        };
        if (choices[choiceIndex].TextEntry === "true") {
          newChoice.textEntry = true;
        }
        question.choices.push(newChoice);
      }

      // TEMPORARY ZIP CODE processing
      // Use this code to output zip codes from the Qualtrics project
      // to the console, then copy to data/ZipCodeData.json
      /*
      if (key === 'QID32') {
        const zips: {[key: string]: string} = {};
        for (const key of Object.keys(choices)) {
          zips[choices[key].Display] = surveyId;
        }
        console.log(JSON.stringify(zips, null, 2));
      } */
    }

    if (element.Language) {
      question.translations = {};
      const qChoices = question.choices!;
      for (const language of Object.keys(element.Language)) {
        const translation = element.Language[language];
        const mappedTranslation: QuestionTranslationSet = {
          questionText: translation.QuestionText,
        }
        if (translation.Choices) {
          mappedTranslation.choices = [];
          for (const choiceIndex of element.ChoiceOrder) {
            const choice = translation.Choices[choiceIndex];
            if (choice?.Display) {
              const newChoice: QuestionChoice = {
                value: `${choiceIndex}`,
                display: choice?.Display.trim(),
              }
              const baseChoice = qChoices.find(c => c.value === newChoice.value);
              if (baseChoice?.textEntry) {
                newChoice.textEntry = true;
              }
              mappedTranslation.choices.push(newChoice);
            }
            else {
              // console.log(`No translation for ${question.questionId} ${language} ${choiceIndex}`);
            }
          }
        }
        question.translations[language] = mappedTranslation;
        
      }
    }

    if (element.Selector === 'MAVR') {
      // allows multiple answers
      question.validationSettings = {
        type: 'MultipleValues',
      };
      if (element.SubSelector === 'TX') {
        // allows multiple answers
        question.validationSettings.subType = 'TextEntry';
      }
    }
    else if (element.Validation) {
      const settings = element.Validation.Settings;
      if (settings?.ContentType && settings.ContentType.toLowerCase() !== 'none') {
        question.validationSettings = {
          type: settings.ContentType,
        };

        if (settings.ContentType === 'ValidNumber') {
          const numberSettings = settings['ValidNumber'];
          if (numberSettings['Min'] !== undefined) {
            question.validationSettings.minimum = Number(numberSettings['Min']);
          }
          if (numberSettings['Max'] !== undefined) {
            question.validationSettings.maximum = Number(numberSettings['Max']);
          }
        }
      }
    }

    questions[question.questionId] = question;
  }

  return questions;
}

export function createResponseSet(surveyId: string, sessionId: string, qualtricsSession: string, language: string): SurveyResponseSet {
  return {
    surveyId,
    sessionId,
    qualtricsSession,
    language,
    responses: {},
    qualtricsResponses: {},
    status: 'started',
  }
}

// retrieves responses for a survey block from the state, converting response values
// to types expected by the Qualtrics API (e.g. converting answers to multiple-choice questions)
export function getResponsesForBlock(state: SurveyState, block: SurveyBlock): ResponseMap {
  const responseMap: ResponseMap = {};
  const sessionId = state.currentSessionId;
  const surveyId = state.currentSurveyId;
  const responseSet = state.responseSets[sessionId];
  const questionSet = state.questionSets[surveyId];
  const questions = questionSet.questions;
  
  for (const questionId of block.questionIds) {
     const question = questions[questionId];
     const response = responseSet.responses[questionId];
     if (question && response) {
        if (question.questionType === 'MC') {
          const mcResponse: { [index: string]: object } = {};
          if (question.validationSettings?.type === 'MultipleValues') {
            const values = response.split(',');
            for (const value of values) {
              const newValue: { [key: string]: string|boolean} = { "selected": true };

              // values with freeform text
              const textValue = responseSet.responses[`${questionId}_${value}`];
              if (textValue) {
                newValue["text"] = textValue;
              }
              mcResponse[value] = newValue;
            }
          }
          else {
            mcResponse[response] = { selected: true };
          }
          responseMap[questionId] = mcResponse;
        }
        else {
          responseMap[questionId] = response;
        }
     }
  }

  return responseMap;
}

export function setQualtricsResponse(responseMap: ResponseMap, question: SurveyQuestion, response: string, choiceId?: string): void {
  if (choiceId) {
    // setting text entry value for a multiple-choice option with freeform text entry
    const key = `${question.questionId}_${choiceId}_TEXT`;
    responseMap[key] = response;
  }
  else if (question.questionType === 'MC') {
    if (question.validationSettings?.type === 'MultipleValues') {
      // These have to be strings, for some reason, or else Qualtrics returns 500 error
      responseMap[question.questionId] = response.split(',');
    } else {
      responseMap[question.questionId] = Number(response);
    }
  }
  else if (question.questionType === 'TE') {
    // text responses have to be indexed by the question ID with "_TEXT" appended
    const key = `${question.questionId}_TEXT`;
    responseMap[key] = (question.validationSettings?.type === 'ValidNumber' ? Number(response) : response);
  }
}
